import { Injectable, signal } from '@angular/core';
import { EvalueeFeatureFlags, PortalSecuritySettings } from '@career-scope/models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeaturesSecurityService {
  private portalSecuritySettingsSubject = new BehaviorSubject<PortalSecuritySettings | null>(null);
  private evalueeFeatureFlagsSubject = new BehaviorSubject<EvalueeFeatureFlags | null>(null);
  private autoLogout = new BehaviorSubject<boolean>(false);
  private audioEnabled = new BehaviorSubject<boolean>(false);
  private exerciseTimeModifier = new BehaviorSubject<number>(1);
  private untimed = new BehaviorSubject<boolean>(false);
  private showTimer = new BehaviorSubject<boolean>(true);
  private superUser = new BehaviorSubject<boolean>(false);

  showSuperUserActions = signal(false);
  skipPracticeQuestions = signal(false);
  isHighContrastMode = signal(false);

  updateFeaturesSecurityInfo(portalSecuritySettings: PortalSecuritySettings, evalueeFeatureFlags: EvalueeFeatureFlags) {
    this.portalSecuritySettingsSubject.next(portalSecuritySettings);
    this.evalueeFeatureFlagsSubject.next(evalueeFeatureFlags);
    
    // This will retrigger the idle check if the autoLogout property gets 'updated'
    const autoLogout = portalSecuritySettings.autoLogout || false;
    if (this.autoLogout.value !== autoLogout) {
      this.autoLogout.next(portalSecuritySettings.autoLogout || false);
    }
  }

  clearFeaturesSecurityInfo() {
    this.portalSecuritySettingsSubject.next(null);
    this.evalueeFeatureFlagsSubject.next(null);
    this.autoLogout.next(false);
  }

  portalSecuritySettings() {
    return this.portalSecuritySettingsSubject;
  }

  evalueeFeatureFlags() {
    return this.evalueeFeatureFlagsSubject;
  }

  getAutoLogout() {
    return this.autoLogout;
  }
  
  getAudioEnabled() {
    return this.audioEnabled;
  }

  setAudioEnabled(enabled: boolean) {
    const audioEnabled = this.audioEnabled.value;
    if (audioEnabled !== enabled) {
      this.audioEnabled.next(enabled);
    }
  }

  getExerciseTimeModifier() {
    return this.exerciseTimeModifier;
  }

  setExerciseTimeModifier(modifier: number) {
    const exerciseTimeModifier = this.exerciseTimeModifier.value;
    if (exerciseTimeModifier !== modifier) {
      console.log('setting exercise time modifier', modifier);
      this.exerciseTimeModifier.next(modifier);
    }
  }

  getUntimed() {
    return this.untimed;
  }

  setUntimed(untimed: boolean) {
    const currentUntimed = this.untimed.value;
    if (currentUntimed !== untimed) {
      this.untimed.next(untimed);
    }
  }

  getShowTimer() {
    return this.showTimer;
  }

  setShowTimer(showTimer: boolean) {
    const currentShowTimer = this.showTimer.value;
    if (currentShowTimer !== showTimer) {
      this.showTimer.next(showTimer);
    }
  }

  getSuperUser() {
    return this.superUser;
  }

  setSuperUser(superUser: boolean) {
    const currentSuperUser = this.superUser.value;
    if (currentSuperUser !== superUser) {
      this.superUser.next(superUser);
    }
  }
}
