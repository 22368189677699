import { Aptitude, AptitudeQuestion, AptitudeTask } from '../models/src';
import { task10questions, task1questions, task3questions, task6questions, task8questions } from './populateAptitudes';

export const spanishTask6questions: AptitudeQuestion[] = [
  { id: 99, question: `J. B. Buttley - J. B. Buttley`, order: 1, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 100, question: `A. Streiker - A. Streiker`, order: 2, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 101, question: `Gerald Gannaway - Gerald Gannoway`, order: 3, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 102, question: `Anthony Minardi - Anthony Minardo`, order: 4, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 103, question: `Axthelm Supply Store - Axthelm Supply Store`, order: 5, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 104, question: `Nettles-Diamond-Ong, Inc. -  Nettles-Diamond-Ong, Inc.`, order: 6, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 105, question: `Barbara Lindsey - Barbara Lindsay`, order: 7, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 106, question: `Randolph Roissman - Randolph Roisman`, order: 8, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 107, question: `J.B. Dietz - J.B. Dietz`, order: 9, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 108, question: `Sturbridge Oaks Inc. - Sturbridge Oaks Inc.`, order: 10, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 109, question: `Dinotech Electronix - Dinotech Electronix`, order: 11, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 110, question: `Fred Zimmery - Fred Zimmery`, order: 12, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 111, question: `J. Zarolinne - J. Zaroliene`, order: 13, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 112, question: `Davidson - Davison`, order: 14, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 113, question: `Tillotson - Tillotsen`, order: 15, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 114, question: `A. B. Murphy - A. B. Murphy`, order: 16, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 115, question: `Morris Zauflik - Morris Zauflik`, order: 17, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 116, question: `Band Bros. Inc. - Band Bros. Inc.`, order: 18, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 117, question: `Spitz's Liquor Store - Spitz's Liquer Store`, order: 19, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 118, question: `Spaetzl Inc. - Spaetzl Inc.`, order: 20, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 119, question: `Jonas Maynardi - Jonas Manardi`, order: 21, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 120, question: `Stephen Kirby, Esq. - Stephen Kirby, Esq.`, order: 22, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 121, question: `Packett-Disk Inc. -  Packett-Disk Inc.`, order: 23, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 122, question: `Stoller Bakery - Stoller Bakery`, order: 24, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 123, question: `John A. Harris - John A. Harris`, order: 25, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 124, question: `T. Hanley - T. Hanley`, order: 26, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 125, question: `Robert Schafzin - Robert Schafsin`, order: 27, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 126, question: `J. B. Gant - J. B. Gant`, order: 28, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 127, question: `T. L. Chapman - T. L. Chapan`, order: 29, choices: ['IGUAL', 'DIFERENTE'] },
  { id: 128, question: `T. Kempner - T. Kemper`, order: 30, choices: ['IGUAL', 'DIFERENTE'] },
];

const spanishTask12questions: AptitudeQuestion[] = [
  {
    id: 195, question: `Usualmente toma 30 minutos para escribir un reporte a máquina. Ayer tomó el doble del tiempo. ¿Cuánto tiempo tomó?`,
    order: 1, choices: ['55 minutos', '1 hora', '1 hora, 10 minutos', 'Ninguno de éstos']
  },
  { id: 196, question: `Un pintor puede pintar una pared con medio galón de pintura. ¿Cuántos galones necesita para pintar 3 paredes?`, order: 2, choices: ['1 1/2 galones', '2 galones', '2 1/3 galones', 'Ninguno de éstos'] },
  { id: 197, question: `Juan gana 9 dólares la hora. ¿Cuánto gana por 40 horas?`, order: 3, choices: ['$340.00', '$360.00', '$357.00', 'Ninguno de éstos'] },
  { id: 198, question: `Alex tiene 1/3 de dinero que Janet. Janet tiene 9 dólares. ¿Cuánto dinero tiene Alex?`, order: 4, choices: ['$3.00', '$15.00', '$27.00', 'Ninguno de éstos'] },
  { id: 199, question: `Los cupcakes de la pastelería de Sally cuestan 5 dólares cada uno. Si Sally vende 35 cupcakes, ¿cuánto dinero gana?`, order: 5, choices: ['$150.00', '$165.00', '$175.00', 'Ninguno de éstos'] },
  { id: 200, question: `Candice gana 780 dólares al mes. Se le descuenta el 10 % en concepto de impuestos. ¿Cuánto paga de impuestos al mes?`, order: 6, choices: ['$7.80', '$70.80', '$78.00', 'Ninguno de éstos'] },
  { id: 201, question: `En el cartel de la tienda se lee $0,20 por huevo. ¿Cuánto costaría una docena de huevos?`, order: 7, choices: ['$2.00', '$2.40', '$24.00', 'Ninguno de éstos'] },
  { id: 202, question: `Se toma ½ de un día de 8 horas para hacer un trabajo. ¿Cuánto tiempo se toma?`, order: 8, choices: ['2 horas', '2 1/2 horas', '3 horas', 'Ninguno de éstos'] },
  {
    id: 203, question: `Joe usualmente trabaja 40 horas por semana. En un año, él trabajó 10 horas adicionales (de tiempo extra) cada semana. ¿Cuál fue el número total de horas que él trabajó en ese año?`,
    order: 9, choices: ['520 horas', '2080 horas', '2600 horas', 'Ninguno de éstos']
  },
  {
    id: 204, question: `Un electricista compró dos cables, uno de 3.25 pies de largo y otro de 2.15 pies de largo. ¿Cuánto mas largo es un cable con respecto al otro?`,
    order: 10, choices: ['.10 pies', '1.10 pies', '5.40 pies', 'Ninguno de éstos']
  },
  { id: 205, question: `Un cuarto tiene 25 pies de largo y 15 pies de ancho. ¿Cuánto pies-cuadrados tiene el cuarto?`, order: 11, choices: ['40 pies cuadrados', '325 pies cuadrados', '375 pies cuadrados', 'Ninguno de éstos'] },
  {
    id: 206, question: `Un equipo de construcción construyó una carretera de 2 millas de largo. Posteriormente, se añadieron 430 pies. ¿Cuántos pies tiene la carretera? (una milla equivale a 5,280 pies)`,
    order: 12, choices: ['5710', '10560', '10990', 'Ninguno de éstos']
  },
  {
    id: 207, question: `En la calle Third, los Johnson tienen cinco hijos, los Lim, dos, los Roseman, cuatro y los Brown, uno. ¿Cuál es el número promedio de hijos de estas familias?`,
    order: 13, choices: ['4', '5', ' 12', 'Ninguno de éstos']
  },
  { id: 208, question: `El impuesto sobre ventas es del 6 por ciento. Si yo compro un articulo por $10.00 más el impuesto. ¿Cuánto cambio recibiré de un billete de $20?`, order: 14, choices: ['$6.00', '$9.40', '$10.60', 'Ninguno de éstos'] },
  { id: 209, question: `Cheryl está haciendo un marco rectangular de 5 x 7 pulgadas para una foto. ¿Cuántos pies de madera necesita?`, order: 15, choices: ['1', '2', '3', 'Ninguno de éstos'] },
  {
    id: 210, question: `Si una llamada telefónica cuesta 2 dólares los 3 primeros minutos y 0,20 dólares cada minuto después de los 3, ¿cuánto costará una llamada de 10 minutos?`,
    order: 16, choices: ['$3.40', '$4.40', '$6.20', 'Ninguno de éstos']
  },
  {
    id: 211, question: `Un cuarto de leche se regó de un tarro de un galón. ¿Cuántas pintas de leche quedaron? (un galón = 4 cuartos; 1 cuarto = 2 pintas)`,
    order: 17, choices: ['6 pintas', '8 pintas', '12 pintas', 'Ninguno de éstos']
  },
  {
    id: 212, question: `Una pareja asegura su vivienda por la mitad de su valor, que es de 70 000 $. El costo del seguro es de 6 $ por cada 1 000 $ del valor asegurado. ¿Cuál sería el costo total del seguro?`,
    order: 18, choices: ['$180.00', '$210.00', '$420.00', 'Ninguno de éstos']
  },
  {
    id: 213, question: `Un chef necesita 1 taza de nata para hacer una tarta de crema de chocolate, y cada tarta es suficiente para 8 personas. ¿Cuántas tazas de nata necesitará si desea preparar tartas para 48 personas?`,
    order: 19, choices: ['6 tazas', '9 tazas', '18 tazas', 'Ninguno de éstos']
  },
  {
    id: 214, question: `Para una receta se necesitan 2 tazas de agua, 1/2 taza de vinagre y 2 tazas de caldo. ¿Cuál es el recipiente más pequeño en el que caben estos ingredientes (4 tazas equivalen a 1 cuarto de galón)?`,
    order: 20, choices: ['1 cuarto de galón', '1 1/8 cuartos de galón', '1 1/2 cuartos de galón', 'Ninguno de éstos']
  },
  { id: 215, question: `Jaime gasta ½ de su dinero en renta (alquiler) y 1/3 de su dinero en comida. ¿Cuánto dinero le sobra para otras cosas?`, order: 21, choices: ['1/9', '1/6', '1/5', 'Ninguno de éstos'] },
  { id: 216, question: `Susana mide 3 pulgadas más que Diana. Diana mide 2 pulgadas menos que Marcus. ¿Cuánto más alta es Susan que Marcus?`, order: 22, choices: ['1 pulgada', '2 pulgadas', '5 pulgadas', 'Ninguno de éstos'] },
  { id: 217, question: `Si el impuesto sobre la renta es del 3 %, ¿Cuántos impuestos pagará Bárbara al año si cobra 250 dólares a la semana?`, order: 23, choices: ['$150.00', '$242.50', '$390.00', 'Ninguno de éstos'] },
];

export const spanishTask14questions: AptitudeQuestion[] = [
  { id: 220, question: `APRETADO`, order: 1, choices: ['DÉBIL', 'SUELTO', 'ANSIOSO', 'VIL'] },
  { id: 221, question: `SILENCIO`, order: 2, choices: ['ENOJO', 'CASTIGAR', 'SONIDO', 'INVESTIGAR'] },
  { id: 222, question: `AMENAZA`, order: 3, choices: ['OBSEQUIO', 'ADVERTENCIA', 'CARAMELO', 'COSTURA'] },
  { id: 223, question: `INSULTAR`, order: 4, choices: ['INDICAR', 'OFENDER', 'DIRIGIR', 'AFRONTAR'] },
  { id: 224, question: `PODRIRSE`, order: 5, choices: ['DETERIORARSE', 'ENGAÑARSE', 'DECIDIR', 'RECHAZAR'] },
  { id: 225, question: `BARRERA`, order: 6, choices: ['OBSTÁCULO', 'ROTACIÓN', 'BASE', 'FANTASIA'] },
  { id: 226, question: `CONFIDENTE`, order: 7, choices: ['AGRESIVO', 'SEGURO', 'PROVECHOSO', 'COMPRENSIVO'] },
  { id: 227, question: `ESPLÉNDIDO`, order: 8, choices: ['LOCAL', 'REAL', 'PRECIPITOSO', 'MAGNIFICO'] },
  { id: 228, question: `PENETRAR`, order: 9, choices: ['IMPRESIONAR', 'ENCARCELAR', 'ACENTUAR', 'ENTRAR'] },
  { id: 229, question: `CONFIADO`, order: 10, choices: ['SOSPECHOSO', 'DESCORTÉS', 'EXISTENTE', 'FASTIDIOSO'] },
  { id: 230, question: `RECTO`, order: 11, choices: ['CORTO', 'SUPERFICIAL', 'TORCIDO', 'ALTO'] },
  { id: 231, question: `CITAR`, order: 12, choices: ['LLAMAR', 'DESANIMAR', 'HERVIR', 'ARRESTAR'] },
  { id: 232, question: `INFINITO`, order: 13, choices: ['ANIÑADO', 'INTERMINABLE', 'REAL', 'MALHUMORADO'] },
  { id: 233, question: `AUTÉNTICO`, order: 14, choices: ['OBSOLETO', 'CONVENIENTE', 'GENUINO', 'ANTIGUO'] },
  { id: 234, question: `CONSOLAR`, order: 15, choices: ['MODERNIZAR', 'PAUSAR', 'CONFORTAR', 'JUSTIFICAR'] },
  { id: 235, question: `FLOJO`, order: 16, choices: ['LLENO', 'TRANQUILO', 'FLEXIBLE', 'INGENUOSO'] },
  { id: 236, question: `EXCUSAR`, order: 17, choices: ['CASTIGAR', 'ILUSTRAR', 'VIVIR', 'COLOCAR'] },
  { id: 237, question: `COMPLACER`, order: 18, choices: ['ASISTIR', 'DESAGRADAR', 'AGRADECER', 'PREGUNTAR'] },
  { id: 238, question: `RELUCIR`, order: 19, choices: ['OSCURECER', 'DESNATAR', 'BRILLAR', 'VISLUMBRAR'] },
  { id: 239, question: `SUPLEMENTARIO`, order: 20, choices: ['ERRÓNEO', 'AUXILIAR', 'FUERTE', 'TONTO'] },
  { id: 240, question: `DISMINUIR`, order: 21, choices: ['OSCURECER', 'AUMENTAR', 'ESTAR EN DIETA', 'DEDICAR'] },
  { id: 241, question: `VENGANZA`, order: 22, choices: ['VIRTUD', 'PRECAUCIÓN', 'PERDÓN', 'AMISTAD'] },
  { id: 242, question: `PRELUDIO`, order: 23, choices: ['CONTRATO', 'INTRODUCCIÓN', 'ORDENANZA', 'ESTIPULACIÓN'] },
  { id: 243, question: `OBSCURO`, order: 24, choices: ['LIENTO', 'BRILLANTE', 'DELGADO', 'PRESUROSO'] },
  { id: 244, question: `ENMENDAR`, order: 25, choices: ['LLEVAR', 'CAMBIAR', 'MOFAR', 'CONSTITUÍR'] },
  { id: 245, question: `MANIPULAR`, order: 26, choices: ['ABUSAR', 'MANEJAR', 'ASOCIAR', 'ELIMINAR'] },
  { id: 246, question: `CONDENSADO`, order: 27, choices: ['CONVOLUTO', 'CLIMATICO', 'NEGLIGENTE', 'ABREVIADO'] },
  { id: 247, question: `ACCESIBLE`, order: 28, choices: ['DISTANTE', 'ADICIONAL', 'ADORABLE', 'MOVIBLE'] },
  { id: 248, question: `DISCRETO`, order: 29, choices: ['EXTRAÑO', 'GALANTE', 'TEMERARIO', 'ELEGANTE'] },
  { id: 249, question: `FRECUENTE`, order: 30, choices: ['OPORTUNO', 'RÍTMICO', 'LIMITADO', 'RARO'] },
];

export const spanishAptitudes: Aptitude[] = [
  {
    type: 'General',
    name: 'Aprendizaje General',
    definition: 'La capacidad de "captar" o comprender instrucciones y principios básicos; junto con la capacidad de razonar y emitir juicios. Estrechamente relacionado con un buen desempeño escolar.',
    jobTasks: 'Realizar diagnósticos y tratamientos de enfermedades o lesiones, utilizar hechos para resolver crímenes, planificar la configuración de redes informáticas, y probar piezas de motores.',
    score: 0,
    letter: 'G',
  },
  {
    type: 'Verbal',
    name: 'Aptitud Verbal',
    definition: 'La capacidad de comprender el significado de las palabras y utilizarlas de manera efectiva, así como la habilidad de comprender el lenguaje para entender las relaciones entre las palabras y comprender el significado de frases y párrafos enteros.',
    jobTasks: 'Escribir una novela; entrevistar a invitados en una tertulia de radio; editar artículos de periódico para su publicación; escribir pie de fotos para revistas; tomar apuntes durante la clase.',
    score: 0,
    letter: 'V',
  },
  {
    type: 'Numerical',
    name: 'Aptitud Numérica',
    definition: 'La capacidad de realizar operaciones aritméticas con rapidez y precisión.',
    jobTasks: 'Analizar datos estadísticos; elaborar presupuestos para una organización; medir espacios en paredes para la instalación de ventanas; añadir listas de números.',
    score: 0,
    letter: 'N',
  },
  {
    type: 'Spatial',
    name: 'Aptitud Espacial',
    definition: 'La capacidad de pensar visualmente en formas geométricas y comprender su representación bidimensional de objetos tridimensionales, así como la capacidad de reconocer las relaciones resultantes del movimiento de objetos en el espacio.',
    jobTasks: 'Diseñar trazados para nuevos sistemas de carreteras; crear diagramas de sistemas de cableado en edificios; utilizar patrones para confeccionar prendas de vestir; manejar una carretilla elevadora; utilizar un plano de planta para encontrar una oficina.',
    score: 0,
    letter: 'S',
  },
  {
    type: 'Form',
    name: 'Percepción de Formas',
    definition: 'La capacidad de percibir detalles en objetos o en material pictórico o gráfico, así como la capacidad de realizar comparaciones y discriminaciones visuales y de ver ligeras diferencias en las formas y sombreados de las figuras y en el ancho y largo de las líneas.',
    jobTasks: 'Examinar y comparar células utilizando un microscopio; comprobar los indicadores de temperatura de la maquinaria; inspeccionar piezas en una cadena de montaje; clasificar mercancías por tamaño.',
    score: 0,
    letter: 'P',
  },
  {
    type: 'Clerical',
    name: 'Percepción Clerical',
    definition: 'La capacidad de percibir detalles relevantes en material verbal o tabular, así como la capacidad de observar diferencias en la copia para corregir palabras y números y evitar errores de percepción en el cálculo aritmético',
    jobTasks: 'Corregir manuscritos con el fin de identificar errores tipográficos; llevar registros de inventario; clasificar el correo según el código postal; manejar una caja registradora.',
    score: 0,
    letter: 'Q',
  }
];

export const spanishAptitudeTasks: AptitudeTask[] = [

  {
    id: 1,
    name: 'Identificación de objetos',
    description: 'Establecer conexiones entre dibujos detallados de objetos',
    iconFile: 'exercise-icons_Object Identification.svg',
    instructions: 'Seleccionar el objeto que sea idéntico al objeto de destino.',
    color: '#208752',
    exitColor: '#78D0A3',
    legacyCode: 'aptbtobj',
    questionType: 'Graphic',
    choiceType: 'Graphic',
    language: 'Universal',
    aptitude: 'Form',
    timeOutLength: 60,
    questions: task1questions,
    questionCount: 30,
    practiceQuestions: [
      { id: 30, question: `p01-00.svg`, order: 0, choices: ['p01-01.svg', 'p01-02.svg', 'p01-03.svg', 'p01-04.svg'], correctAnswer: 4 },
      { id: 30, question: `p02-00.svg`, order: 0, choices: ['p02-01.svg', 'p02-02.svg', 'p02-03.svg', 'p02-04.svg'], correctAnswer: 3 }
    ],
    complete: false
  },

  {
    id: 3,
    name: 'Formas Abstractas', // 'Abstract Shape Matching',
    description: 'Asociar formas que pueden ser giradas o volteadas entre sí',
    iconFile: 'exercise-icons_Abstract Shapes.svg',
    color: '#2F78C0',
    exitColor: '#A0CCF8',
    instructions: 'Seleccionar la forma que sea idéntica a la forma de destino, incluso si está girada o volteada.',
    legacyCode: 'aptbtabs',
    questionType: 'Graphic',
    choiceType: 'Graphic',
    language: 'Universal',
    aptitude: 'Form',
    timeOutLength: 150,
    questions: task3questions,
    questionCount: 30,
    practiceQuestions: [
      { id: 60, question: `p01-00.svg`, order: 0, choices: ['p01-01.svg', 'p01-02.svg', 'p01-03.svg', 'p01-04.svg'], correctAnswer: 4 },
      { id: 60, question: `p02-00.svg`, order: 0, choices: ['p02-01.svg', 'p02-02.svg', 'p02-03.svg', 'p02-04.svg'], correctAnswer: 3 }
    ],
    complete: false
  },

  {
    id: 6,
    name: 'Asignación de Correspondencia',
    description: 'Identificar si el texto es igual o diferente',
    iconFile: 'exercise-icons_Clerical Matching.svg',
    color: '#69008C',
    exitColor: '#E7ACFB',
    instructions: 'Si ambos nombres son idénticos, haga clic en la palabra "IGUAL". Si los dos nombres son diferentes, haga clic en la palabra "DIFERENTE".',
    legacyCode: 'aptbtclr',
    questionType: 'Text',
    choiceType: 'Text',
    language: 'Universal',
    aptitude: 'Clerical',
    timeOutLength: 75,
    questions: spanishTask6questions,
    questionCount: 30,
    practiceQuestions: [
      { id: 600, question: `Alex Johnson - Alex Johnson`, order: 0, choices: ['IGUAL', 'DIFERENTE'], correctAnswer: 1 },
      { id: 601, question: `John Samson - John Sanson`, order: 0, choices: ['IGUAL', 'DIFERENTE'], correctAnswer: 2 }
    ],
    complete: false
  },

  {
    id: 8,
    name: 'Visualización de Patrones',
    description: 'Reconocer formas tridimensionales a partir de su patrón de plegado.',
    iconFile: 'exercise-icons_Pattern Visualization.svg',
    exitColor: '#A8D4FF',
    color: '#0F4C81',
    instructions: 'Seleccionar la forma que se crearía al doblar el patrón objetivo sobre las líneas de puntos.',
    legacyCode: 'aptpatvz',
    questionType: 'Graphic',
    choiceType: 'Graphic',
    language: 'Universal',
    aptitude: 'Spatial',
    timeOutLength: 300,
    questions: task8questions,
    questionCount: 30,
    practiceQuestions: [
      { id: 131, question: `p01-00.svg`, order: 0, choices: ['p01-01.svg', 'p01-02.svg', 'p01-03.svg', 'p01-04.svg'], correctAnswer: 4 },
      { id: 131, question: `p02-00.svg`, order: 0, choices: ['p02-01.svg', 'p02-02.svg', 'p02-03.svg', 'p02-04.svg'], correctAnswer: 4 },
    ],
    complete: false
  },

  {
    id: 10,
    name: 'Cómputo',
    description: 'Sumar, restar, multiplicar y dividir números',
    iconFile: 'exercise-icons_Computation.svg',
    color: '#BF3945',
    exitColor: '#FD7F8A',
    instructions: 'Resolver el problema matemático y seleccionar la respuesta correcta. Si la respuesta correcta no aparece, elegir "Ninguno de éstos". Puede utilizar papel y lápiz, <strong>pero no calculadora</strong>.',
    legacyCode: 'aptbtcom',
    questionType: 'Equation',
    choiceType: 'Text',
    language: 'Spanish',
    aptitude: 'Numerical',
    timeOutLength: 300,
    questions: task10questions,
    questionCount: 30,
    practiceQuestions: [
      { id: 1000, equation: { numbers: [527, 5], operator: '&minus;' }, order: 0, choices: ['520', '522', '532', 'Ninguno de éstos'], correctAnswer: 2 },
      { id: 1001, equation: { numbers: [16, 4], operator: '&divide;' }, order: 0, choices: ['2', '3', '4', 'Ninguno de éstos'], correctAnswer: 3 },
    ],
    complete: false
  },

  {
    id: 12,
    name: 'Razonamiento numérico',
    description: 'Resolver problemas matemáticos',
    iconFile: 'exercise-icons_Numerical Reasoning.svg',
    color: '#7E60E2',
    exitColor: '#D8CEF9',
    instructions: 'Lee el problema y elige la respuesta correcta. Si no aparece la respuesta correcta, elija "Ninguno de éstos". Puede utilizar papel y lápiz, <strong>pero no calculadora</strong>.',
    legacyCode: 'aptbtnum',
    questionType: 'Text',
    choiceType: 'Text',
    language: 'Spanish',
    aptitude: 'Numerical',
    timeOutLength: 420,
    questions: spanishTask12questions,
    questionCount: 23,
    practiceQuestions: [
      {
        id: 1200, question: `Maria gana $10.00 por hora. ¿Cuánto gana en una semana de 40 horas?`,
        order: 0, choices: ['$128.95', '$160.00', '$400.00', 'Ninguno de éstos'], correctAnswer: 3
      },
      {
        id: 1201, question: `Una madera tiene 8 pies de largo. Si usted corta 2 pies, de la madera. ¿Cuánto queda?`,
        order: 0, choices: ['2 PIES', '3 PIES', '4 PIES', 'Ninguno de éstos'], correctAnswer: 4
      },
    ],
    complete: false
  },

  {
    id: 14,
    name: 'El Significados de las Palabras',
    description: 'Identificar palabras con significados iguales u opuestos',
    iconFile: 'exercise-icons_Word Meanings.svg',
    color: '#CA4092',
    exitColor: '#ECA5CF',
    instructions: 'Seleccionar la palabra que comparta el significado más PARECIDO o más OPUESTO con la palabra objetivo.',
    legacyCode: 'aptbtwdm',
    questionType: 'Text',
    choiceType: 'Text',
    language: 'Spanish',
    aptitude: 'Verbal',
    timeOutLength: 135,
    questions: spanishTask14questions,
    questionCount: 30,
    practiceQuestions: [
      { id: 1400, question: `ALTO`, order: 0, choices: ['BAJO', 'DIFÍCIL', 'BLANCO', 'NUEVO'], correctAnswer: 1 },
      { id: 1401, question: `CHISTOSO`, order: 0, choices: ['DINERO', 'INCOMÓDO', 'DIVERTIDO', 'LEAL'], correctAnswer: 3 },
    ],
    complete: false
  }
];
