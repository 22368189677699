import { Injectable, inject, signal } from '@angular/core';
import { Assessment } from '@career-scope/models';
import { BehaviorSubject, Observable, Subscription, of, tap } from 'rxjs';
import { doc, docData, DocumentReference, Firestore, updateDoc} from '@angular/fire/firestore';



@Injectable({
  providedIn: 'root'
})
export class AssessmentService {
  firestore = inject(Firestore);
  assessment = new BehaviorSubject<Assessment | null | undefined>(null);
  assessment$: Observable<Assessment | undefined> = of(undefined);
  assessmentSubscription: Subscription | null = null;

  calculatingResults = signal(false);
  quizInProgress = signal(false);

  constructor() {
    this.handleAssessmentChanges();
  }

  setAssessmentObservable(portalId: string, evalueeId: string, assessmentId: string) {
    const assessment = this.assessment.value;

    if (assessment && assessment.id === assessmentId) {
      return;
    }

    const assessmentDocRef = doc(this.firestore, `portals/${portalId}/evaluees/${evalueeId}/assessments/${assessmentId}`) as DocumentReference<Assessment>;
    this.assessment$ = docData(assessmentDocRef).pipe(
      tap((assessment) => {
        if (!assessment) {
          this.assessment.next(undefined);
          return;
        }
        this.assessment.next(assessment);
      })
    );

    this.assessmentSubscription = this.assessment$.pipe(
      tap(assessment => this.assessment.next(assessment))
    ).subscribe();
  }

  private handleAssessmentChanges(): void {
    this.assessment.pipe(
      tap(assessment => {
        if (!assessment) {
          return;
        }

        // If the assessment is complete and the scores are not calculated then we are letting the user know we are calculating the scores
        if (assessment.exercisesComplete && assessment.interestsComplete) {
          const calculationFunctionRunning = assessment.aptitudeScores.length === 0 && assessment.interestScores.length === 0;
          this.calculatingResults.set(calculationFunctionRunning);
        }
      })
    ).subscribe();
  }

  saveAssessment(assessment: Partial<Assessment>, portalId: string, evalueeId: string, assessmentId: string) {
    const assessmentRef = doc(this.firestore, `portals/${portalId}/evaluees/${evalueeId}/assessments/${assessmentId}`) as DocumentReference<Assessment>;
    updateDoc(assessmentRef, { ...assessment, lastModifiedDate: new Date(Date.now()) });
  }

  setAssessmentNullAndUnsubscribe() {
    this.assessment.next(null);
    this.assessmentSubscription?.unsubscribe();
  }
}