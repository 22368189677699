import { Component, OnInit, HostListener } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { ShellComponent } from './shared/shell/shell.component';
import { FeaturesSecurityService } from './_services/features-security.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [ShellComponent, RouterOutlet]
})
export class AppComponent implements OnInit {
  title = 'CareerScope';
  showAppShell = false;

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.fss.getSuperUser().value) {
      // Check for Option+Shift+S (Mac) or Alt+Shift+S (Windows/Linux)
      if (event.altKey && event.shiftKey && (event.key.toLowerCase() === 's' || event.code === 'KeyS')) {
        event.preventDefault(); // Prevent default browser save dialog
        console.log('Shortcut triggered - toggling showSuperUserActions');
        this.fss.showSuperUserActions.update(value => !value);
      }
      if (event.altKey && event.shiftKey && (event.key.toLowerCase() === 'p' || event.code === 'KeyP')) {
        event.preventDefault(); // Prevent default browser save dialog
        console.log('Shortcut triggered - toggling skipPracticeQuestions');
        this.fss.skipPracticeQuestions.update(value => !value);
      }
    }
  }

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private titleService: Title,
    private router: Router,
    private fss: FeaturesSecurityService
  ) {
    this.matIconRegistry.addSvgIcon(
      'btn_start',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/Btn-start.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'btn_check',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/Btn-check.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'solid_check',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/solid-check.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'duration',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/Recent.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'check_circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/check-circle.svg')
    );


    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateAppShellVisibility(this.router.url);
      }
    });
  }

  updateAppShellVisibility(url: string): void {
    // TODO: Update urls
    this.showAppShell = !url?.includes('login') && !url?.includes('portal') && !url?.includes('username');
  }

  scrollTopNavContent(): void {
    const sideNav = document.querySelector('.mat-sidenav-content');

    if (sideNav) {
      sideNav.scrollTop = 0;
    }
  }



  ngOnInit() {
    this.titleService.setTitle('CareerScope');
  }
}
