import { Injectable, LOCALE_ID, WritableSignal, inject, signal } from '@angular/core';
import { BaseAptitudeService } from './base-aptitude.service';
import { Aptitude, AptitudeTask } from '@career-scope/models';
import { aptitudes, aptitudeTasks, spanishAptitudes, spanishAptitudeTasks } from '@career-scope/libs/data';

@Injectable()
export class CareerScopeAptitudeService extends BaseAptitudeService {
  locale = inject(LOCALE_ID);
  tasks: WritableSignal<AptitudeTask[]> = signal(this.locale === 'en' ? aptitudeTasks : spanishAptitudeTasks);
  aptitudes: WritableSignal<Aptitude[]> = signal(this.locale === 'en' ? aptitudes : spanishAptitudes);
} 