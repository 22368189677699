import { Injectable, signal, OnDestroy } from '@angular/core';
import { BaseInterestService } from './base-interest.service';
import { ConsumerDirectInterestService } from './consumer-direct-interest.service';
import { TesterlyInterestService } from './testerly-interest.service';
import { CareerScopeInterestService } from './career-scope-interest.service';
import { map } from 'rxjs/operators';
import { Subscription, combineLatest } from 'rxjs';

@Injectable()
export class InterestServiceProvider extends BaseInterestService implements OnDestroy {
  private currentService?: BaseInterestService;
  private subscription?: Subscription;

  constructor() {
    super();
    this.initializeService();
  }

  private initializeService() {
    // Clean up any existing subscription
    this.subscription?.unsubscribe();
    
    this.subscription = combineLatest([
      this.evalueeService.evaluee.pipe(),
      this.assessmentService.assessment.pipe()
    ]).pipe(
      map(([evaluee, assessment]) => {

        // If the evaluee or assessment is undefined, we need to throw an error and reset the current service as evaluee or assessment is not found
        if (evaluee === undefined || assessment === undefined) {
          this.currentService = undefined;  
          throw new Error(`${!evaluee ? 'Evaluee' : 'Assessment'} not found`);
        }

        // If the evaluee or assessment is null, we need to reset the current service when assessment is null as that means its empty (sign out)
        if (evaluee === null || assessment === null) {
          this.currentService = undefined;
          return;
        }

        

        // If the current service is already set, we don't need to create a new one
        if (this.currentService) {
          this.currentService.setTopInterests(assessment.topInterestScores);
          return;
        }

        // Create new service instance
        if (evaluee.consumerDirect) {
          this.currentService = new ConsumerDirectInterestService();
        } else if (assessment.interestInventoryType === 'testerly') {
          this.currentService = new TesterlyInterestService();
        } else {
          this.currentService = new CareerScopeInterestService();
        }
        this.currentService.setInterestInventoryAnswers(assessment.evalueeAnswers);
        this.currentService.setTopInterests(assessment.topInterestScores);
      })
    ).subscribe();
  }

  override ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.currentService = undefined;
  }

  // Implement all abstract methods by delegating to currentService
  get interests() { return this.currentService?.interests || signal([]); }
  get interestQs() { return this.currentService?.interestQs || signal([]); }
  get INTEREST_INVENTORY_TASK_ID() { return this.currentService?.INTEREST_INVENTORY_TASK_ID || 0; }
  get topInterests() { return this.currentService?.topInterests || signal([]); }
  get ratingConfigOptions() { return this.currentService?.ratingConfigOptions || signal([]); }
}
