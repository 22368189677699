import { Injectable, OnDestroy, Signal, WritableSignal, computed, inject, signal } from '@angular/core';
import { EvalueeAnswer, Interest, InterestQuestion, InterestResult, InterestScore } from '@career-scope/models';
import { EvalueeService } from './evaluee.service';
import { Subscription } from 'rxjs';
import { AssessmentService } from './assessment.service';
import { RatingOption } from '../shared/components/rating-options/rating-options.component';

@Injectable()
export abstract class BaseInterestService implements OnDestroy {
  protected evalueeService = inject(EvalueeService);
  protected assessmentService = inject(AssessmentService);
  private assessmentSubscription: Subscription;

  consumerDirectEvaluee = signal(false);
  interestQsLength = computed(() => this.interestQs().length > 140 ? 140 : 130);
  interestsLength = computed(() => this.interests().length);
  answerOptionsCount = computed(() => this.ratingConfigOptions().length);

  constructor() {
    this.assessmentSubscription = this.assessmentService.assessment.subscribe(assessment => {
      if (assessment === null) {
        this.resetInterestInventoryAnswers();
      }
    });
  }

  abstract interests: WritableSignal<Interest[]>;
  abstract interestQs: WritableSignal<InterestQuestion[]>;
  abstract ratingConfigOptions: WritableSignal<RatingOption[]>;
  abstract topInterests: WritableSignal<(Interest & InterestScore)[]>;
  abstract INTEREST_INVENTORY_TASK_ID: number;

  completeInterestsHQ() {
    this.completeInterestQuestions();
    this.saveInterestAnswers();
  }

  updateQuestions(questions: InterestQuestion[]) {
    this.interestQs.set(questions);
  }

  setTopInterests(topInterestScores: InterestScore[]) {
    this.topInterests.set(topInterestScores.map(score => {
      const matchingInterest = this.interests().find(interest => interest.interestCategory === score.interestCategory) as Interest;
      return {
        ...score,
        ...matchingInterest,
      };
    }));
  }

  getInterestQuestions(): Signal<InterestQuestion[]> {
    return this.interestQs;
  }

  getInterestInventoryAnswers(): EvalueeAnswer[] {
    return this.interestQs().filter(q => !!q.answer).map(q => ({
      taskId: this.INTEREST_INVENTORY_TASK_ID,
      questionId: q.id,
      answer: q.answer as number,
      timeToComplete: q.timeToComplete ? Math.floor(q.timeToComplete) : 0,
    }));
  }

  setInterestInventoryAnswers(evalueeAnswers: EvalueeAnswer[]) {
    const interestAnswers = evalueeAnswers.filter(answer => answer.taskId === this.INTEREST_INVENTORY_TASK_ID);

    interestAnswers.forEach(answer => {
      const serviceQuestion = this.interestQs().find(q => q.id === answer.questionId);
      if (serviceQuestion) {
        serviceQuestion.answer = answer.answer;
      }
    });
  }


  hasInterestInventoryProgress(): boolean {
    return this.interestQs().some(q => q.answer);
  }

  isInterestInventoryComplete(): boolean {
    return this.interestQs().every(q => q.answer);
  }

  private resetInterestInventoryAnswers() {
    this.interestQs.update(qs => qs.map(q => ({ ...q, answer: null, timeToComplete: 0 })));
  }

  private completeInterestQuestions() {
    this.interestQs.update(questions => questions.map(q => q.answer ? q : { ...q, answer: Math.floor(Math.random() * this.answerOptionsCount() + 1), timeToComplete: 0 }));
  }

  resetInterestsHQ() {
    if (!this.evalueeService.evaluee.value?.assessmentSettings?.superUser) {
      return;
    }

    const assessment = this.assessmentService.assessment.value;

    if (!assessment) {
      return;
    }

    const evalueeAnswers = assessment.evalueeAnswers.filter(answer => answer.taskId !== this.INTEREST_INVENTORY_TASK_ID);

    this.assessmentService.saveAssessment({ evalueeAnswers, interestResults: [], interestsComplete: null, interestScores: [], topInterestScores: [], completedDate: null, lastModifiedDate: new Date(Date.now()) }, assessment.portalId, assessment.evalueeId, assessment.id);
    this.evalueeService.updateEvaluee({ interestComplete: null, lastActivity: 'Interests Reset', lastActivityDate: new Date(Date.now()), interestStatus: 'Interests Reset', status: 'in progress' });
    this.resetInterestInventoryAnswers();
  }

  saveInterestAnswers() {
    const assessment = this.assessmentService.assessment.value;

    if (!assessment) {
      return;
    }

    const filteredAnswers = assessment.evalueeAnswers.filter(answer => answer.taskId !== this.INTEREST_INVENTORY_TASK_ID);
    const interestAnswers = this.getInterestInventoryAnswers();

    const evalueeAnswers = [...filteredAnswers, ...interestAnswers];

    const interestCompletedPercent = Math.round(interestAnswers.length / this.getInterestQuestions()().length * 100);

    const interestsComplete = this.getInterestQuestions()().length === interestAnswers.length ? new Date(Date.now()) : null;
    const lastActivity = interestsComplete ? 'Interest Completed' : 'Interest ' + interestCompletedPercent + '% complete';
    const interestStatus = interestCompletedPercent + '%';
    const lastActivityDate = new Date(Date.now());
    const interestResults: InterestResult[] = [];
    const interestScores: InterestScore[] = [];
    const topInterestScores: InterestScore[] = [];

    this.assessmentService.saveAssessment({ evalueeAnswers, interestResults, interestsComplete, interestScores, topInterestScores }, assessment.portalId, assessment.evalueeId, assessment.id);

    this.evalueeService.updateEvaluee({ interestComplete: interestsComplete, lastActivity, lastActivityDate, interestStatus });
  }

  ngOnDestroy(): void {
    this.assessmentSubscription?.unsubscribe();
  }
} 