import { Routes } from '@angular/router';
import { authGuard } from './user/auth.guard';
import { MyPathGuard } from './user/my-path.guard';
import { AssessmentCompletedGuard } from './assessment/guards/assessment-completed.guard';
import { ValidateProfileInformationGuard } from './shared/validate-profile-information.guard';
import { allowLoginPageGuard } from './user/login.guard';
import { assessmentGuard } from './shared/assessment.guard';
import { PathOccupationService } from './path/services/path-occupation.service';
import { environment } from '../environments/environment';
import { interestTypeGuard } from './assessment/guards/interest-type.guard';
import { BaseInterestService } from './_services/base-interest.service';
import { InterestServiceProvider } from './_services/interest-service.factory';
import { BaseAptitudeService } from './_services/base-aptitude.service';
import { AptitudeServiceProvider } from './_services/aptitude-service.factory';
import { ImagesService } from './_services/images.service';

export const routes: Routes = [
  
  { path: 'login', redirectTo: !environment.vx ? 'login/' : 'username', pathMatch: 'full' },
  { path: 'login/:uuid', loadComponent: () => import('./user/login-page/login-page.component').then(m => m.LoginPageComponent), canActivate: [allowLoginPageGuard] },
  { path: 'portal/:id', loadComponent: () => import('./user/portal-login/portal-login.component').then(m => m.PortalLoginComponent) },
  { path: 'whitelist', loadComponent: () => import('./whitelist/whitelist.component').then(m => m.WhitelistComponent) },
  { path: 'username', loadComponent: () => import('./user/username-login/username-login.component').then(m => m.UsernameLoginComponent) },
  { path: 'external-login/:jwt', loadComponent: () => import('./user/external-evaluee-login/external-evaluee-login.component').then(m => m.ExternalEvalueeLoginComponent) },
  {
    path: '',
    providers: [
      { provide: BaseInterestService, useClass: InterestServiceProvider },
      { provide: BaseAptitudeService, useClass: AptitudeServiceProvider },
      { provide: ImagesService }
    ],
    children: [
      { path: '', loadComponent: () => import('./home/home.component').then(m => m.HomeComponent), canActivate: [authGuard, ValidateProfileInformationGuard, assessmentGuard] },
      { path: 'profile', loadComponent: () => import('./user/profile-dialog/profile-dialog.component').then(m => m.ProfileDialogComponent), canActivate: [authGuard] },
      { path: 'exercises', loadComponent: () => import('./assessment/exercises/exercises.component').then(m => m.ExercisesComponent), canActivate: [authGuard, ValidateProfileInformationGuard, assessmentGuard] },
      { path: 'aptoldtest/:task', loadComponent: () => import('./assessment/aptitude-quiz/aptitude-quiz.component').then(m => m.AptitudeQuizComponent), canActivate: [authGuard, AssessmentCompletedGuard, ValidateProfileInformationGuard, assessmentGuard] },
      { path: 'apttest/:task', loadComponent: () => import('./assessment/modern-aptitude-quiz/modern-aptitude-quiz.component').then(m => m.ModernAptitudeQuizComponent), canActivate: [authGuard, AssessmentCompletedGuard, ValidateProfileInformationGuard, assessmentGuard] },
      { path: 'results', loadComponent: () => import('./results/results.component').then(m => m.ResultsComponent), canActivate: [authGuard, ValidateProfileInformationGuard, assessmentGuard] },
      { path: 'survey', loadComponent: () => import('./survey/survey.component').then(m => m.SurveyComponent), canActivate: [authGuard, ValidateProfileInformationGuard, assessmentGuard] },
      { path: 'path', loadChildren: () => import('./path/path-routing').then(m => m.routes), canActivate: [authGuard, MyPathGuard, ValidateProfileInformationGuard, assessmentGuard], providers: [PathOccupationService] },
      { path: 'no-path', loadComponent: () => import('./no-path/no-path.component').then(m => m.NoPathComponent), canActivate: [authGuard, ValidateProfileInformationGuard, assessmentGuard] },
      { path: 'interests', loadComponent: () => import('./assessment/interests/interests.component').then(m => m.InterestsComponent), canActivate: [authGuard, ValidateProfileInformationGuard, assessmentGuard] },
      { path: 'inttest2', loadComponent: () => import('./assessment/interest-scope/interest-scope.component').then(m => m.InterestScopeComponent), canActivate: [authGuard, ValidateProfileInformationGuard, assessmentGuard, interestTypeGuard] },
      { path: 'inttest', loadComponent: () => import('./assessment/interest-inventory/interest-inventory.component').then(m => m.InterestInventoryComponent), canActivate: [authGuard, ValidateProfileInformationGuard, assessmentGuard, interestTypeGuard] }
    ]
  },
  { path: '**', redirectTo: '' }
];
