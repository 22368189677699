import { Interest, InterestQuestion } from '@career-scope/models';

export const testerlyInterestQuestions: InterestQuestion[] = [
  // Add your Testerly questions here following this format:
  {
    id: 1001,
    interestCategory: 'Art',
    question: 'Your Testerly question here',
    order: 1
  },
  {
    id: 1002,
    interestCategory: 'Art',
    question: 'Your Testerly question here 2',
    order: 2
  },
  {
    id: 1003,
    interestCategory: 'Art',
    question: 'Your Testerly question here 3',
    order: 3
  },
  {
    id: 1004,
    interestCategory: 'Art',
    question: 'Your Testerly question here 4',
    order: 4
  },
  {
    id: 1005,
    interestCategory: 'Art',
    question: 'Your Testerly question here 5',
    order: 5
  },
  // ... more questions ...
];

export const spanishTesterlyInterestQuestions: InterestQuestion[] = [
  // Add Spanish versions of Testerly questions here
  {
    id: 1001,
    interestCategory: 'Art',
    question: 'Your Spanish Testerly question here',
    order: 1
  },
  {
    id: 1002,
    interestCategory: 'Art',
    question: 'Your Spanish Testerly question here 2',
    order: 2
  },
  {
    id: 1003,
    interestCategory: 'Art',
    question: 'Your Spanish Testerly question here 3',
    order: 3
  },
  {
    id: 1004,
    interestCategory: 'Art',
    question: 'Your Spanish Testerly question here 4',
    order: 4
  },
  {
    id: 1005,
    interestCategory: 'Art',
    question: 'Your Spanish Testerly question here 5',
    order: 5
  },
  // ... more questions ...
]; 

export const testerlyInterests: Interest[] = [
  {
    interestCategory: 'Art',
    name: 'Artistic - Testerly',
    description: 'An interest in the creative expression of feelings or ideas.',
    jobTasks: 'An interest in creative expression of feeling or ideas through literary arts, visual arts, performing arts, or crafts.',
    jobExamples: 'Writer, Painter, Actor, Editor, Dancer, Singer, Graphic Designer, Set Designer',
    color: '#ee509c',
    svgLogo: '/assets/images/icons/report_interest_artistic_icon.svg',
    whiteSvgLogo: '/assets/images/icons/report_interest_artistic_icon_white.svg',
    totalQuestions: 14,
  },
];

export const spanishTesterlyInterests: Interest[] = [
  {
    interestCategory: 'Art',
    name: 'Artistic - Spanish',
    description: 'An interest in the creative expression of feelings or ideas.',
    jobTasks: 'An interest in creative expression of feeling or ideas through literary arts, visual arts, performing arts, or crafts.',
    jobExamples: 'Writer, Painter, Actor, Editor, Dancer, Singer, Graphic Designer, Set Designer',
    color: '#ee509c',
    svgLogo: '/assets/images/icons/report_interest_artistic_icon.svg',
    whiteSvgLogo: '/assets/images/icons/report_interest_artistic_icon_white.svg',
    totalQuestions: 14,
  },
]; 