import { Aptitude, AptitudeQuestion, AptitudeTask } from '@career-scope/models';

export const aptitudes: Aptitude[] = [
    {
        type: 'General',
        name: 'General Learning',
        definition: 'The ability to "catch on" or understand instructions and underlying principles; ability to reason and make judgements. Closely related to doing well in school.',
        jobTasks: 'Diagnose and treat illnesses or injuries; use facts to solve a crime; plan the layout of a computer network; inspect and test engine parts.',
        score: 0,
        letter: 'G',
    },
    {
        type: 'Verbal',
        name: 'Verbal Aptitude',
        definition: 'The ability to understand the meaning of words and to use them effectively; ability to comprehend language, to understand relationships between words, and to understand the meanings of whole sentences and paragraphs.',
        jobTasks: 'Write a novel; interview guests on a radio talk show; edit newspaper articles for publication; write captions for magazine photos; take notes during class.',
        score: 0,
        letter: 'V',
    },
    {
        type: 'Numerical',
        name: 'Numerical Aptitude',
        definition: 'The ability to perform arithmetic operations quickly and accurately.',
        jobTasks: 'Analyze statistical data; develop budgets for an organization; measure wall openings to fit and install windows; add lists of numbers.',
        score: 0,
        letter: 'N',
    },
    {
        type: 'Spatial',
        name: 'Spatial Aptitude',
        definition: 'The ability to think visually of geometric forms and to comprehend the two- dimensional representation of three-dimensional objects; ability to recognize the relationships resulting from the movement of objects in space.',
        jobTasks: 'Design layouts for new highway systems; create diagrams of wiring systems in buildings; use patterns to make clothing; operate a forklift; use a floor plan to find an office.',
        score: 0,
        letter: 'S',
    },
    {
        type: 'Form',
        name: 'Form Perception',
        definition: 'The ability to perceive detail in objects or in pictorial or graphic material; ability to make visual comparisons and discriminations and see slight differences in shapes and shadings of figures and widths and lengths of lines.',
        jobTasks: 'Examine and compare cells under a microscope; check temperature gauges on machinery; inspect parts on an assembly line; sort merchandise by size.',
        score: 0,
        letter: 'P',
    },
    {
        type: 'Clerical',
        name: 'Clerical Perception',
        definition: 'The ability to perceive pertinent detail in verbal or tabular material; ability to observe differences in copy, to proofread words and numbers, and to avoid perceptual errors in arithmetic computation.',
        jobTasks: 'Proofread manuscripts for typographical errors; keep inventory records; sort mail according to zip code; operate a cash register.',
        score: 0,
        letter: 'Q',
    }
];

// Object Identification
export const task1questions: AptitudeQuestion[] = [
    { id: 30, question: `01-00.svg`, order: 1, choices: ['01-01.svg', '01-02.svg', '01-03.svg', '01-04.svg'] },
    { id: 31, question: `02-00.svg`, order: 2, choices: ['02-01.svg', '02-02.svg', '02-03.svg', '02-04.svg'] },
    { id: 32, question: `03-00.svg`, order: 3, choices: ['03-01.svg', '03-02.svg', '03-03.svg', '03-04.svg'] },
    { id: 33, question: `04-00.svg`, order: 4, choices: ['04-01.svg', '04-02.svg', '04-03.svg', '04-04.svg'] },
    { id: 34, question: `05-00.svg`, order: 5, choices: ['05-01.svg', '05-02.svg', '05-03.svg', '05-04.svg'] },
    { id: 35, question: `06-00.svg`, order: 6, choices: ['06-01.svg', '06-02.svg', '06-03.svg', '06-04.svg'] },
    { id: 36, question: `07-00.svg`, order: 7, choices: ['07-01.svg', '07-02.svg', '07-03.svg', '07-04.svg'] },
    { id: 37, question: `08-00.svg`, order: 8, choices: ['08-01.svg', '08-02.svg', '08-03.svg', '08-04.svg'] },
    { id: 38, question: `09-00.svg`, order: 9, choices: ['09-01.svg', '09-02.svg', '09-03.svg', '09-04.svg'] },
    { id: 39, question: `10-00.svg`, order: 10, choices: ['10-01.svg', '10-02.svg', '10-03.svg', '10-04.svg'] },
    { id: 40, question: `11-00.svg`, order: 11, choices: ['11-01.svg', '11-02.svg', '11-03.svg', '11-04.svg'] },
    { id: 41, question: `12-00.svg`, order: 12, choices: ['12-01.svg', '12-02.svg', '12-03.svg', '12-04.svg'] },
    { id: 42, question: `13-00.svg`, order: 13, choices: ['13-01.svg', '13-02.svg', '13-03.svg', '13-04.svg'] },
    { id: 43, question: `14-00.svg`, order: 14, choices: ['14-01.svg', '14-02.svg', '14-03.svg', '14-04.svg'] },
    { id: 44, question: `15-00.svg`, order: 15, choices: ['15-01.svg', '15-02.svg', '15-03.svg', '15-04.svg'] },
    { id: 45, question: `16-00.svg`, order: 16, choices: ['16-01.svg', '16-02.svg', '16-03.svg', '16-04.svg'] },
    { id: 46, question: `17-00.svg`, order: 17, choices: ['17-01.svg', '17-02.svg', '17-03.svg', '17-04.svg'] },
    { id: 47, question: `18-00.svg`, order: 18, choices: ['18-01.svg', '18-02.svg', '18-03.svg', '18-04.svg'] },
    { id: 48, question: `19-00.svg`, order: 19, choices: ['19-01.svg', '19-02.svg', '19-03.svg', '19-04.svg'] },
    { id: 49, question: `20-00.svg`, order: 20, choices: ['20-01.svg', '20-02.svg', '20-03.svg', '20-04.svg'] },
    { id: 50, question: `21-00.svg`, order: 21, choices: ['21-01.svg', '21-02.svg', '21-03.svg', '21-04.svg'] },
    { id: 51, question: `22-00.svg`, order: 22, choices: ['22-01.svg', '22-02.svg', '22-03.svg', '22-04.svg'] },
    { id: 52, question: `23-00.svg`, order: 23, choices: ['23-01.svg', '23-02.svg', '23-03.svg', '23-04.svg'] },
    { id: 53, question: `24-00.svg`, order: 24, choices: ['24-01.svg', '24-02.svg', '24-03.svg', '24-04.svg'] },
    { id: 54, question: `25-00.svg`, order: 25, choices: ['25-01.svg', '25-02.svg', '25-03.svg', '25-04.svg'] },
    { id: 55, question: `26-00.svg`, order: 26, choices: ['26-01.svg', '26-02.svg', '26-03.svg', '26-04.svg'] },
    { id: 56, question: `27-00.svg`, order: 27, choices: ['27-01.svg', '27-02.svg', '27-03.svg', '27-04.svg'] },
    { id: 57, question: `28-00.svg`, order: 28, choices: ['28-01.svg', '28-02.svg', '28-03.svg', '28-04.svg'] },
    { id: 58, question: `29-00.svg`, order: 29, choices: ['29-01.svg', '29-02.svg', '29-03.svg', '29-04.svg'] },
    { id: 59, question: `30-00.svg`, order: 30, choices: ['30-01.svg', '30-02.svg', '30-03.svg', '30-04.svg'] },
];
// Shape Matching
export const task3questions: AptitudeQuestion[] = [
    { id: 62, question: `01-00.svg`, order: 1, choices: ['01-01.svg', '01-02.svg', '01-03.svg', '01-04.svg'] },
    { id: 63, question: `02-00.svg`, order: 2, choices: ['02-01.svg', '02-02.svg', '02-03.svg', '02-04.svg'] },
    { id: 64, question: `03-00.svg`, order: 3, choices: ['03-01.svg', '03-02.svg', '03-03.svg', '03-04.svg'] },
    { id: 65, question: `04-00.svg`, order: 4, choices: ['04-01.svg', '04-02.svg', '04-03.svg', '04-04.svg'] },
    { id: 66, question: `05-00.svg`, order: 5, choices: ['05-01.svg', '05-02.svg', '05-03.svg', '05-04.svg'] },
    { id: 67, question: `06-00.svg`, order: 6, choices: ['06-01.svg', '06-02.svg', '06-03.svg', '06-04.svg'] },
    { id: 68, question: `07-00.svg`, order: 7, choices: ['07-01.svg', '07-02.svg', '07-03.svg', '07-04.svg'] },
    { id: 69, question: `08-00.svg`, order: 8, choices: ['08-01.svg', '08-02.svg', '08-03.svg', '08-04.svg'] },
    { id: 70, question: `09-00.svg`, order: 9, choices: ['09-01.svg', '09-02.svg', '09-03.svg', '09-04.svg'] },
    { id: 71, question: `10-00.svg`, order: 10, choices: ['10-01.svg', '10-02.svg', '10-03.svg', '10-04.svg'] },
    { id: 72, question: `11-00.svg`, order: 11, choices: ['11-01.svg', '11-02.svg', '11-03.svg', '11-04.svg'] },
    { id: 73, question: `12-00.svg`, order: 12, choices: ['12-01.svg', '12-02.svg', '12-03.svg', '12-04.svg'] },
    { id: 74, question: `13-00.svg`, order: 13, choices: ['13-01.svg', '13-02.svg', '13-03.svg', '13-04.svg'] },
    { id: 75, question: `14-00.svg`, order: 14, choices: ['14-01.svg', '14-02.svg', '14-03.svg', '14-04.svg'] },
    { id: 76, question: `15-00.svg`, order: 15, choices: ['15-01.svg', '15-02.svg', '15-03.svg', '15-04.svg'] },
    { id: 77, question: `16-00.svg`, order: 16, choices: ['16-01.svg', '16-02.svg', '16-03.svg', '16-04.svg'] },
    { id: 78, question: `17-00.svg`, order: 17, choices: ['17-01.svg', '17-02.svg', '17-03.svg', '17-04.svg'] },
    { id: 79, question: `18-00.svg`, order: 18, choices: ['18-01.svg', '18-02.svg', '18-03.svg', '18-04.svg'] },
    { id: 80, question: `19-00.svg`, order: 19, choices: ['19-01.svg', '19-02.svg', '19-03.svg', '19-04.svg'] },
    { id: 81, question: `20-00.svg`, order: 20, choices: ['20-01.svg', '20-02.svg', '20-03.svg', '20-04.svg'] },
    { id: 82, question: `21-00.svg`, order: 21, choices: ['21-01.svg', '21-02.svg', '21-03.svg', '21-04.svg'] },
    { id: 83, question: `22-00.svg`, order: 22, choices: ['22-01.svg', '22-02.svg', '22-03.svg', '22-04.svg'] },
    { id: 84, question: `23-00.svg`, order: 23, choices: ['23-01.svg', '23-02.svg', '23-03.svg', '23-04.svg'] },
    { id: 85, question: `24-00.svg`, order: 24, choices: ['24-01.svg', '24-02.svg', '24-03.svg', '24-04.svg'] },
    { id: 86, question: `25-00.svg`, order: 25, choices: ['25-01.svg', '25-02.svg', '25-03.svg', '25-04.svg'] },
    { id: 87, question: `26-00.svg`, order: 26, choices: ['26-01.svg', '26-02.svg', '26-03.svg', '26-04.svg'] },
    { id: 88, question: `27-00.svg`, order: 27, choices: ['27-01.svg', '27-02.svg', '27-03.svg', '27-04.svg'] },
    { id: 89, question: `28-00.svg`, order: 28, choices: ['28-01.svg', '28-02.svg', '28-03.svg', '28-04.svg'] },
    { id: 90, question: `29-00.svg`, order: 29, choices: ['29-01.svg', '29-02.svg', '29-03.svg', '29-04.svg'] },
    { id: 91, question: `30-00.svg`, order: 30, choices: ['30-01.svg', '30-02.svg', '30-03.svg', '30-04.svg'] },
];
export const task6questions: AptitudeQuestion[] = [
    { id: 99, question: `J. B. Buttley - J. B. Buttley`, order: 1, choices: ['SAME', 'DIFFERENT'] },
    { id: 100, question: `A. Streiker - A. Streiker`, order: 2, choices: ['SAME', 'DIFFERENT'] },
    { id: 101, question: `Gerald Gannaway - Gerald Gannoway`, order: 3, choices: ['SAME', 'DIFFERENT'] },
    { id: 102, question: `Anthony Minardi - Anthony Minardo`, order: 4, choices: ['SAME', 'DIFFERENT'] },
    { id: 103, question: `Axthelm Supply Store - Axthelm Supply Store`, order: 5, choices: ['SAME', 'DIFFERENT'] },
    { id: 104, question: `Nettles-Diamond-Ong, Inc. -  Nettles-Diamond-Ong, Inc.`, order: 6, choices: ['SAME', 'DIFFERENT'] },
    { id: 105, question: `Barbara Lindsey - Barbara Lindsay`, order: 7, choices: ['SAME', 'DIFFERENT'] },
    { id: 106, question: `Randolph Roissman - Randolph Roisman`, order: 8, choices: ['SAME', 'DIFFERENT'] },
    { id: 107, question: `J.B. Dietz - J.B. Dietz`, order: 9, choices: ['SAME', 'DIFFERENT'] },
    { id: 108, question: `Sturbridge Oaks Inc. - Sturbridge Oaks Inc.`, order: 10, choices: ['SAME', 'DIFFERENT'] },
    { id: 109, question: `Dinotech Electronix - Dinotech Electronix`, order: 11, choices: ['SAME', 'DIFFERENT'] },
    { id: 110, question: `Fred Zimmery - Fred Zimmery`, order: 12, choices: ['SAME', 'DIFFERENT'] },
    { id: 111, question: `J. Zarolinne - J. Zaroliene`, order: 13, choices: ['SAME', 'DIFFERENT'] },
    { id: 112, question: `Davidson - Davison`, order: 14, choices: ['SAME', 'DIFFERENT'] },
    { id: 113, question: `Tillotson - Tillotsen`, order: 15, choices: ['SAME', 'DIFFERENT'] },
    { id: 114, question: `A. B. Murphy - A. B. Murphy`, order: 16, choices: ['SAME', 'DIFFERENT'] },
    { id: 115, question: `Morris Zauflik - Morris Zauflik`, order: 17, choices: ['SAME', 'DIFFERENT'] },
    { id: 116, question: `Band Bros. Inc. - Band Bros. Inc.`, order: 18, choices: ['SAME', 'DIFFERENT'] },
    { id: 117, question: `Spitz's Liquor Store - Spitz's Liquer Store`, order: 19, choices: ['SAME', 'DIFFERENT'] },
    { id: 118, question: `Spaetzl Inc. - Spaetzl Inc.`, order: 20, choices: ['SAME', 'DIFFERENT'] },
    { id: 119, question: `Jonas Maynardi - Jonas Manardi`, order: 21, choices: ['SAME', 'DIFFERENT'] },
    { id: 120, question: `Stephen Kirby, Esq. - Stephen Kirby, Esq.`, order: 22, choices: ['SAME', 'DIFFERENT'] },
    { id: 121, question: `Packett-Disk Inc. -  Packett-Disk Inc.`, order: 23, choices: ['SAME', 'DIFFERENT'] },
    { id: 122, question: `Stoller Bakery - Stoller Bakery`, order: 24, choices: ['SAME', 'DIFFERENT'] },
    { id: 123, question: `John A. Harris - John A. Harris`, order: 25, choices: ['SAME', 'DIFFERENT'] },
    { id: 124, question: `T. Hanley - T. Hanley`, order: 26, choices: ['SAME', 'DIFFERENT'] },
    { id: 125, question: `Robert Schafzin - Robert Schafsin`, order: 27, choices: ['SAME', 'DIFFERENT'] },
    { id: 126, question: `J. B. Gant - J. B. Gant`, order: 28, choices: ['SAME', 'DIFFERENT'] },
    { id: 127, question: `T. L. Chapman - T. L. Chapan`, order: 29, choices: ['SAME', 'DIFFERENT'] },
    { id: 128, question: `T. Kempner - T. Kemper`, order: 30, choices: ['SAME', 'DIFFERENT'] },
];
export const task8questions: AptitudeQuestion[] = [
    { id: 131, question: `01-00.svg`, order: 1, choices: ['01-01.svg', '01-02.svg', '01-03.svg', '01-04.svg'] },
    { id: 132, question: `02-00.svg`, order: 2, choices: ['02-01.svg', '02-02.svg', '02-03.svg', '02-04.svg'] },
    { id: 133, question: `03-00.svg`, order: 3, choices: ['03-01.svg', '03-02.svg', '03-03.svg', '03-04.svg'] },
    { id: 134, question: `04-00.svg`, order: 4, choices: ['04-01.svg', '04-02.svg', '04-03.svg', '04-04.svg'] },
    { id: 135, question: `05-00.svg`, order: 5, choices: ['05-01.svg', '05-02.svg', '05-03.svg', '05-04.svg'] },
    { id: 136, question: `06-00.svg`, order: 6, choices: ['06-01.svg', '06-02.svg', '06-03.svg', '06-04.svg'] },
    { id: 137, question: `07-00.svg`, order: 7, choices: ['07-01.svg', '07-02.svg', '07-03.svg', '07-04.svg'] },
    { id: 138, question: `08-00.svg`, order: 8, choices: ['08-01.svg', '08-02.svg', '08-03.svg', '08-04.svg'] },
    { id: 139, question: `09-00.svg`, order: 9, choices: ['09-01.svg', '09-02.svg', '09-03.svg', '09-04.svg'] },
    { id: 140, question: `10-00.svg`, order: 10, choices: ['10-01.svg', '10-02.svg', '10-03.svg', '10-04.svg'] },
    { id: 141, question: `11-00.svg`, order: 11, choices: ['11-01.svg', '11-02.svg', '11-03.svg', '11-04.svg'] },
    { id: 142, question: `12-00.svg`, order: 12, choices: ['12-01.svg', '12-02.svg', '12-03.svg', '12-04.svg'] },
    { id: 143, question: `13-00.svg`, order: 13, choices: ['13-01.svg', '13-02.svg', '13-03.svg', '13-04.svg'] },
    { id: 144, question: `14-00.svg`, order: 14, choices: ['14-01.svg', '14-02.svg', '14-03.svg', '14-04.svg'] },
    { id: 145, question: `15-00.svg`, order: 15, choices: ['15-01.svg', '15-02.svg', '15-03.svg', '15-04.svg'] },
    { id: 146, question: `16-00.svg`, order: 16, choices: ['16-01.svg', '16-02.svg', '16-03.svg', '16-04.svg'] },
    { id: 147, question: `17-00.svg`, order: 17, choices: ['17-01.svg', '17-02.svg', '17-03.svg', '17-04.svg'] },
    { id: 148, question: `18-00.svg`, order: 18, choices: ['18-01.svg', '18-02.svg', '18-03.svg', '18-04.svg'] },
    { id: 149, question: `19-00.svg`, order: 19, choices: ['19-01.svg', '19-02.svg', '19-03.svg', '19-04.svg'] },
    { id: 150, question: `20-00.svg`, order: 20, choices: ['20-01.svg', '20-02.svg', '20-03.svg', '20-04.svg'] },
    { id: 151, question: `21-00.svg`, order: 21, choices: ['21-01.svg', '21-02.svg', '21-03.svg', '21-04.svg'] },
    { id: 152, question: `22-00.svg`, order: 22, choices: ['22-01.svg', '22-02.svg', '22-03.svg', '22-04.svg'] },
    { id: 153, question: `23-00.svg`, order: 23, choices: ['23-01.svg', '23-02.svg', '23-03.svg', '23-04.svg'] },
    { id: 154, question: `24-00.svg`, order: 24, choices: ['24-01.svg', '24-02.svg', '24-03.svg', '24-04.svg'] },
    { id: 155, question: `25-00.svg`, order: 25, choices: ['25-01.svg', '25-02.svg', '25-03.svg', '25-04.svg'] },
    { id: 156, question: `26-00.svg`, order: 26, choices: ['26-01.svg', '26-02.svg', '26-03.svg', '26-04.svg'] },
    { id: 157, question: `27-00.svg`, order: 27, choices: ['27-01.svg', '27-02.svg', '27-03.svg', '27-04.svg'] },
    { id: 158, question: `28-00.svg`, order: 28, choices: ['28-01.svg', '28-02.svg', '28-03.svg', '28-04.svg'] },
    { id: 159, question: `29-00.svg`, order: 29, choices: ['29-01.svg', '29-02.svg', '29-03.svg', '29-04.svg'] },
    { id: 160, question: `30-00.svg`, order: 30, choices: ['30-01.svg', '30-02.svg', '30-03.svg', '30-04.svg'] },
];
export const task10questions: AptitudeQuestion[] = [
    { id: 163, question: `01-00.png`, equation: { numbers: [27, 3], operator: '&times;' }, order: 1, choices: ['81', '61', '87', 'None of these'] },
    { id: 164, question: `02-00.png`, equation: { numbers: [2, 9, 3], operator: '+' }, order: 2, choices: ['24', '14', '13', 'None of these'] },
    { id: 165, question: `03-00.png`, equation: { numbers: [152, 309], operator: '+' }, order: 3, choices: ['461', '451', '551', 'None of these'] },
    { id: 166, question: `04-00.png`, equation: { numbers: [13, 5], operator: '&minus;' }, order: 4, choices: ['18', '8', '65', 'None of these'] },
    { id: 167, question: `05-00.png`, equation: { numbers: [207, 9], operator: '&divide;' }, order: 5, choices: ['22', '33', '32', 'None of these'] },
    { id: 168, question: `06-00.png`, equation: { numbers: [932, 6], operator: '&times;' }, order: 6, choices: ['5,582', '5,592', '5,682', 'None of these'] },
    { id: 169, question: `07-00.png`, equation: { numbers: [43, 26], operator: '&times;' }, order: 7, choices: ['1,118', '118', '1,018', 'None of these'] },
    { id: 170, question: `08-00.png`, equation: { numbers: [1032, 967, 2163], operator: '+' }, order: 8, choices: ['4,012', '4,162', '4,152', 'None of these'] },
    { id: 171, question: `09-00.png`, equation: { numbers: [455, 7], operator: '&divide;' }, order: 9, choices: ['65', '68', '55', 'None of these'] },
    { id: 172, question: `10-00.png`, equation: { numbers: [481, 39], operator: '&minus;' }, order: 10, choices: ['432', '452', '442', 'None of these'] },
    { id: 173, question: `11-00.png`, equation: { numbers: [567, 9], operator: '&divide;' }, order: 11, choices: ['53', '61', '63', 'None of these'] },
    { id: 174, question: `12-00.png`, equation: { numbers: [8701, 18], operator: '&minus;' }, order: 12, choices: ['8,683', '8,693', '8,793', 'None of these'] },
    { id: 175, question: `13-00.png`, equation: { numbers: [7307, 18], operator: '&minus;' }, order: 13, choices: ['7,389', '7,299', '7,289', 'None of these'] },
    { id: 176, question: `14-00.png`, equation: { numbers: [37247, 355], operator: '+' }, order: 14, choices: ['37,592', '37,502', '37,602', 'None of these'] },
    { id: 177, question: `15-00.png`, equation: { numbers: [2456, 8], operator: '&divide;' }, order: 15, choices: ['307', '302', '37', 'None of these'] },
    { id: 178, question: `16-00.png`, equation: { numbers: [4826, 421, 178], operator: '+' }, order: 16, choices: ['5,415', '5,325', '5,425', 'None of these'] },
    { id: 179, question: `17-00.png`, equation: { numbers: [3106, 4], operator: '&times;' }, order: 17, choices: ['12,444', '1,242', '12,424', 'None of these'] },
    { id: 180, question: `18-00.png`, equation: { numbers: [24864, 8], operator: '&divide;' }, order: 18, choices: ['3,108', '3,180', '318', 'None of these'] },
    { id: 181, question: `19-00.png`, equation: { numbers: [7032, 39], operator: '&minus;' }, order: 19, choices: ['6,193', '6,903', '6,993', 'None of these'] },
    { id: 182, question: `20-00.png`, equation: { numbers: [2631, 41], operator: '&times;' }, order: 20, choices: ['13,155', '107,871', '106,871', 'None of these'] },
    { id: 183, question: `21-00.png`, equation: { numbers: [56889, 9], operator: '&divide;' }, order: 21, choices: ['631', '5,321', '6,321', 'None of these'] },
    { id: 184, question: `22-00.png`, equation: { numbers: [4126, 7], operator: '&times;' }, order: 22, choices: ['28,782', '28,872', '28,842', 'None of these'] },
    { id: 185, question: `23-00.png`, equation: { numbers: [332, 23], operator: '&times;' }, order: 23, choices: ['1,660', '6,536', '7,636', 'None of these'] },
    { id: 186, question: `24-00.png`, equation: { numbers: [7341, 6352, 211, 243], operator: '+' }, order: 24, choices: ['14,047', '14,147', '13,147', 'None of these'] },
    { id: 187, question: `25-00.png`, equation: { numbers: [121554, 9], operator: '&divide;' }, order: 25, choices: ['10,356', '13,506', '10,336', 'None of these'] },
    { id: 188, question: `26-00.png`, equation: { numbers: [203453, 641, 5340, 17312, 131165], operator: '+' }, order: 26, choices: ['357,901', '357,911', '347,911', 'None of these'] },
    { id: 189, question: `27-00.png`, equation: { numbers: [3094165, 285436], operator: '&minus;' }, order: 27, choices: ['2,808,629', '288,729', '2,808,729', 'None of these'] },
    { id: 190, question: `28-00.png`, equation: { numbers: [23971, 30], operator: '&times;' }, order: 28, choices: ['719,130', '719,133', '71,913', 'None of these'] },
    { id: 191, question: `29-00.png`, equation: { numbers: [93241, 940, 5821, 1002, 46502], operator: '+' }, order: 29, choices: ['974,675', '984,675', '986,675', 'None of these'] },
    { id: 192, question: `30-00.png`, equation: { numbers: [1724072, 34], operator: '&divide;' }, order: 30, choices: ['50,608', '57,008', '50,780', 'None of these'] },
];
export const task12questions: AptitudeQuestion[] = [
    {
        id: 195, question: `It usually takes 30 minutes to type the report.  Yesterday it took twice as long.  How long did it take?`,
        order: 1, choices: ['55 minutes', '1 hour', '1 hour, 10 minutes', 'None of these']
    },
    { id: 196, question: `A painter can paint one wall with one-half gallon of paint.  How many gallons do they need for 3 walls?`, order: 2, choices: ['1 1/2 gallons', '2 gallons', '2 1/3 gallons', 'None of these'] },
    { id: 197, question: `John earns $9.00 an hour.  How much does he make for 40 hours?`, order: 3, choices: ['$340.00', '$360.00', '$357.00', 'None of these'] },
    { id: 198, question: `Alex has 1/3 as much money as Janet.  Janet has $9.00.  How much money does Alex have?`, order: 4, choices: ['$3.00', '$15.00', '$27.00', 'None of these'] },
    { id: 199, question: `Cupcakes at Sally's bakery cost $5.00 each.  If Sally sells 35 cupcakes how much money does she make?`, order: 5, choices: ['$150.00', '$165.00', '$175.00', 'None of these'] },
    { id: 200, question: `Candice earns $780.00 per month.  Ten percent is taken out for taxes.  How much tax does she pay per month?`, order: 6, choices: ['$7.80', '$70.80', '$78.00', 'None of these'] },
    { id: 201, question: `The sign at the store read $0.20 per egg.  How much would a dozen eggs cost?`, order: 7, choices: ['$2.00', '$2.40', '$24.00', 'None of these'] },
    { id: 202, question: `It required 1/2 of an 8-hour day to do a job.  How long did it take?`, order: 8, choices: ['2 hours', '2 1/2 hours', '3 hours', 'None of these'] },
    {
        id: 203, question: `Joe usually works a 40-hour work week.  One year, he worked 10 hours overtime each week.  What was the total number of hours he worked that year?`,
        order: 9, choices: ['520 hours', '2080 hours', '2600 hours', 'None of these']
    },
    {
        id: 204, question: `An electrician bought two cables, 3.25 feet long and 2.15 feet long.  How much longer was one cable than the other?`,
        order: 10, choices: ['.10 feet', '1.10 feet', '5.40 feet', 'None of these']
    },
    { id: 205, question: `A room is 25 feet long and 15 feet wide.  How many square feet is it?`, order: 11, choices: ['40 square feet', '325 square feet', '375 square feet', 'None of these'] },
    {
        id: 206, question: `A construction team made a road 2 miles long.  Later 430 feet were added.  How many feet was the road?  (one mile equals 5280 feet)`,
        order: 12, choices: ['5710', '10560', '10990', 'None of these']
    },
    {
        id: 207, question: `On Third Street, the Johnsons have five children, the Lims have two, the Rosemans have four, and the Browns have one.  What is the average number of children for these families?`,
        order: 13, choices: ['4', '5', ' 12', 'None of these']
    },
    { id: 208, question: `Sales tax is 6 percent.  If I buy an item for $10.00 plus tax, how much change will I get from a $20 bill?`, order: 14, choices: ['$6.00', '$9.40', '$10.60', 'None of these'] },
    { id: 209, question: `Cheryl is making a 5 inch x 7 inch rectangular frame for a picture.  How many feet of wood does she need?`, order: 15, choices: ['1', '2', '3', 'None of these'] },
    {
        id: 210, question: `If a phone call cost $2.00 for the first 3 minutes and $.20 for each minute after 3, how much will a 10-minute call cost?`,
        order: 16, choices: ['$3.40', '$4.40', '$6.20', 'None of these']
    },
    {
        id: 211, question: `One quart of milk leaked from a gallon jug.  How many pints of milk were left? (4 quarts equal 1 gallon; 2 pints equals 1 quart)`,
        order: 17, choices: ['6 pints', '8 pints', '12 pints', 'None of these']
    },
    {
        id: 212, question: `A couple insured their $70,000 home for 1/2 of its value.  The cost of the insurance is $6 per $1,000 of the insured value.  What is the total insurance cost?`,
        order: 18, choices: ['$180.00', '$210.00', '$420.00', 'None of these']
    },
    {
        id: 213, question: `A chef needs 1 cup of cream for one chocolate cream pie.  Each pie serves 8 people.  How much cream is needed if 48 people want pie?`,
        order: 19, choices: ['6 cups', '9 cups', '18 cups', 'None of these']
    },
    {
        id: 214, question: `A recipe calls for 2 cups of water, 1/2 cup of vinegar and 2 cups of broth.  What is the smallest size bowl which will hold these ingredients (4 cups equal 1 quart)?`,
        order: 20, choices: ['1 quart', '1 1/8 quarts', '1 1/2 quarts', 'None of these']
    },
    { id: 215, question: `Jeff spends 1/2 of his money on rent and 1/3 of his money on food.  How much of his money is left for other things?`, order: 21, choices: ['1/9', '1/6', '1/5', 'None of these'] },
    { id: 216, question: `Susan is 3 inches taller than Diane.  Diane is 2 inches shorter than Marcus.  How much taller is Susan than Marcus?`, order: 22, choices: ['1 inch', '2 inches', '5 inches', 'None of these'] },
    { id: 217, question: `Income tax is 3%.  How much tax is paid in a year if Barbara is paid $250 a week?`, order: 23, choices: ['$150.00', '$242.50', '$390.00', 'None of these'] },

];


export const task14questions: AptitudeQuestion[] = [
    { id: 220, question: `TIGHT`, order: 1, choices: ['WEAK', 'LOOSE', 'ANXIOUS', 'MEAN'] },
    { id: 221, question: `SILENCE`, order: 2, choices: ['ANGER', 'PUNISH', 'SOUND', 'RESEARCH'] },
    { id: 222, question: `THREAT`, order: 3, choices: ['TREAT', 'WARNING', 'CANDY', 'SEAM'] },
    { id: 223, question: `INSULT`, order: 4, choices: ['INDICATE', 'OFFEND', 'DIRECT', 'FACE'] },
    { id: 224, question: `DECAY`, order: 5, choices: ['DETERIORATE', 'DECEIVE', 'DECIDE', 'DEJECT'] },
    { id: 225, question: `BARRIER`, order: 6, choices: ['OBSTACLE', 'ROTATION', 'BASE', 'FANTASY'] },
    { id: 226, question: `CONFIDENT`, order: 7, choices: ['AGGRESSIVE', 'ASSURED', 'PROFITABLE', 'UNDERSTANDING'] },
    { id: 227, question: `SUPERB`, order: 8, choices: ['LOCAL', 'REALISTIC', 'DASHING', 'MAGNIFICENT'] },
    { id: 228, question: `PENETRATE`, order: 9, choices: ['IMPRESS', 'IMPRISON', 'ACCENT', 'ENTER'] },
    { id: 229, question: `TRUSTING`, order: 10, choices: ['DOUBTING', 'UNKIND', 'EXISTING', 'ANNOYING'] },
    { id: 230, question: `STRAIGHT`, order: 11, choices: ['SHORT', 'SUPERFICIAL', 'CROOKED', 'TALL'] },
    { id: 231, question: `SUMMON`, order: 12, choices: ['CALL', 'DETER', 'BOIL', 'ARREST'] },
    { id: 232, question: `INFINITE`, order: 13, choices: ['BABYISH', 'ENDLESS', 'REAL', 'MOROSE'] },
    { id: 233, question: `AUTHENTIC`, order: 14, choices: ['OBSOLETE', 'CONVENIENT', 'GENUINE', 'ANTIQUE'] },
    { id: 234, question: `CONSOLE`, order: 15, choices: ['MODERNIZE', 'PAUSE', 'COMFORT', 'JUSTIFY'] },
    { id: 235, question: `DULL`, order: 16, choices: ['FULL', 'QUIET', 'FLEXIBLE', 'WITTY'] },
    { id: 236, question: `EXCUSE`, order: 17, choices: ['PUNISH', 'ILLUSTRATE', 'LIVE', 'PLACE'] },
    { id: 237, question: `PLEASE`, order: 18, choices: ['ASSIST', 'DISPLEASE', 'THANK', 'ASK'] },
    { id: 238, question: `GLISTEN`, order: 19, choices: ['DARKEN', 'SKIM', 'GLITTER', 'GLANCE'] },
    { id: 239, question: `SUPPLEMENTARY`, order: 20, choices: ['MISTAKEN', 'AUXILIARY', 'STRONG', 'SILLY'] },
    { id: 240, question: `DIMINISH`, order: 21, choices: ['DARKEN', 'INCREASE', 'DIET', 'DEVOTE'] },
    { id: 241, question: `VENGEANCE`, order: 22, choices: ['VIRTUE', 'CAUTION', 'FORGIVENESS', 'FRIENDLINESS'] },
    { id: 242, question: `PRELUDE`, order: 23, choices: ['CONTRACT', 'INTRODUCTION', 'ORDINANCE', 'STIPULATION'] },
    { id: 243, question: `DUSKY`, order: 24, choices: ['DANK', 'BRIGHT', 'SLIM', 'SPEEDY'] },
    { id: 244, question: `AMEND`, order: 25, choices: ['CARRY', 'CHANGE', 'MOCK', 'CONSTITUTE'] },
    { id: 245, question: `MANIPULATE`, order: 26, choices: ['ABUSE', 'HANDLE', 'ASSOCIATE', 'ELIMINATE'] },
    { id: 246, question: `CONDENSED`, order: 27, choices: ['CONVOLUTED', 'CLIMATIC', 'NEGLECTED', 'ABRIDGED'] },
    { id: 247, question: `ACCESSIBLE`, order: 28, choices: ['APPROACHABLE', 'ADDITIONAL', 'DISTANT', 'MOVABLE'] },
    { id: 248, question: `DISCREET`, order: 29, choices: ['ODD', 'GALLANT', 'RASH', 'STYLISH'] },
    { id: 249, question: `FREQUENT`, order: 30, choices: ['TIMELY', 'RHYTHMIC', 'LIMITED', 'RARE'] },
];



export const aptitudeTasks: AptitudeTask[] = [

    {
        id: 1,
        name: 'Object Identification',
        description: 'Match detailed drawings of objects',
        iconFile: 'exercise-icons_Object Identification.svg',
        instructions: 'Choose the object that is exactly the same as the target object.',
        color: '#208752',
        exitColor: '#78D0A3',
        legacyCode: 'aptbtobj',
        questionType: 'Graphic',
        choiceType: 'Graphic',
        language: 'Universal',
        aptitude: 'Form',
        timeOutLength: 60,
        questions: task1questions,
        questionCount: 30,
        practiceQuestions: [
            { id: 30, question: `p01-00.svg`, order: 0, choices: ['p01-01.svg', 'p01-02.svg', 'p01-03.svg', 'p01-04.svg'], correctAnswer: 4 },
            { id: 30, question: `p02-00.svg`, order: 0, choices: ['p02-01.svg', 'p02-02.svg', 'p02-03.svg', 'p02-04.svg'], correctAnswer: 3 }
        ],
        complete: false
    },

    {
        id: 3,
        name: 'Abstract Shapes', // 'Abstract Shape Matching',
        description: 'Match shapes that may be rotated or flipped',
        iconFile: 'exercise-icons_Abstract Shapes.svg',
        color: '#2F78C0',
        exitColor: '#A0CCF8',
        instructions: 'Choose the shape that is exactly the same as the target shape, even if it is rotated or flipped.',
        legacyCode: 'aptbtabs',
        questionType: 'Graphic',
        choiceType: 'Graphic',
        language: 'Universal',
        aptitude: 'Form',
        timeOutLength: 150,
        questions: task3questions,
        questionCount: 30,
        practiceQuestions: [
            { id: 60, question: `p01-00.svg`, order: 0, choices: ['p01-01.svg', 'p01-02.svg', 'p01-03.svg', 'p01-04.svg'], correctAnswer: 4 },
            { id: 60, question: `p02-00.svg`, order: 0, choices: ['p02-01.svg', 'p02-02.svg', 'p02-03.svg', 'p02-04.svg'], correctAnswer: 3 }
        ],
        complete: false
    },

    {
        id: 6,
        name: 'Clerical Matching',
        description: 'Identify whether text is the same or different',
        iconFile: 'exercise-icons_Clerical Matching.svg',
        color: '#69008C',
        exitColor: '#E7ACFB',
        instructions: 'If both names are <strong><u>exactly</u></strong> the same, click on the word &quot;SAME&quot;.   If the two names are different, in any way, click on the word &quot;DIFFERENT&quot;.',
        legacyCode: 'aptbtclr',
        questionType: 'Text',
        choiceType: 'Text',
        language: 'Universal',
        aptitude: 'Clerical',
        timeOutLength: 75,
        questions: task6questions,
        questionCount: 30,
        practiceQuestions: [
            { id: 600, question: `Alex Johnson - Alex Johnson`, order: 0, choices: ['SAME', 'DIFFERENT'], correctAnswer: 1 },
            { id: 601, question: `John Samson - John Sanson`, order: 0, choices: ['SAME', 'DIFFERENT'], correctAnswer: 2 }
        ],
        complete: false
    },

    {
        id: 8,
        name: 'Pattern Visualization',
        description: 'Identify three-dimensional shapes by their folding pattern',
        iconFile: 'exercise-icons_Pattern Visualization.svg',
        exitColor: '#A8D4FF',
        color: '#0F4C81',
        instructions: 'Choose the shape that would be created if the target pattern was folded on the dotted lines.',
        legacyCode: 'aptpatvz',
        questionType: 'Graphic',
        choiceType: 'Graphic',
        language: 'Universal',
        aptitude: 'Spatial',
        timeOutLength: 300,
        questions: task8questions,
        questionCount: 30,
        practiceQuestions: [
            { id: 131, question: `p01-00.svg`, order: 0, choices: ['p01-01.svg', 'p01-02.svg', 'p01-03.svg', 'p01-04.svg'], correctAnswer: 4 },
            { id: 131, question: `p02-00.svg`, order: 0, choices: ['p02-01.svg', 'p02-02.svg', 'p02-03.svg', 'p02-04.svg'], correctAnswer: 4 },
        ],
        complete: false
    },

    {
        id: 10,
        name: 'Computation',
        description: 'Add, subtract, multiply, or divide numbers',
        iconFile: 'exercise-icons_Computation.svg',
        color: '#BF3945',
        exitColor: '#FD7F8A',
        instructions: 'Solve the math problem and choose the correct answer.  If the correct answer does not appear, choose "NONE OF THESE".  You may use paper and pencil, <strong>but not a calculator</strong>.',
        legacyCode: 'aptbtcom',
        questionType: 'Equation',
        choiceType: 'Text',
        language: 'English',
        aptitude: 'Numerical',
        timeOutLength: 300,
        questions: task10questions,
        questionCount: 30,
        practiceQuestions: [
            { id: 1000, equation: { numbers: [527, 5], operator: '&minus;' }, order: 0, choices: ['520', '522', '532', 'None of these'], correctAnswer: 2 },
            { id: 1001, equation: { numbers: [16, 4], operator: '&divide;' }, order: 0, choices: ['2', '3', '4', 'None of these'], correctAnswer: 3 },
        ],
        complete: false
    },

    {
        id: 12,
        name: 'Numerical Reasoning',
        description: 'Solve mathematical word problems',
        iconFile: 'exercise-icons_Numerical Reasoning.svg',
        color: '#7E60E2',
        exitColor: '#D8CEF9',
        instructions: 'Read the problem and choose the correct answer.  If the correct answer does not appear, choose "NONE OF THESE".  You may use paper and pencil, <strong>but not a calculator</strong>.',
        legacyCode: 'aptbtnum',
        questionType: 'Text',
        choiceType: 'Text',
        language: 'English',
        aptitude: 'Numerical',
        timeOutLength: 420,
        questions: task12questions,
        questionCount: 23,
        practiceQuestions: [
            {
                id: 1200, question: `Mary earns $10.00 an hour. How much does she make in a 40-hour week?`,
                order: 0, choices: ['$128.95', '$160.00', '$400.00', 'None of these'], correctAnswer: 3
            },
            {
                id: 1201, question: `A board is 8 feet long. If a piece 2 feet long is cut off, how long is the rest of the board?`,
                order: 0, choices: ['2 FEET', '3 FEET', '4 FEET', 'None of these'], correctAnswer: 4
            },
        ],
        complete: false
    },

    {
        id: 14,
        name: 'Word Meanings',
        description: 'Identify words that have the same or opposite meanings',
        iconFile: 'exercise-icons_Word Meanings.svg',
        color: '#CA4092',
        exitColor: '#ECA5CF',
        instructions: 'Choose the word which is most nearly the SAME or most nearly the OPPOSITE in meaning to the target word.',
        legacyCode: 'aptbtwdm',
        questionType: 'Text',
        choiceType: 'Text',
        language: 'English',
        aptitude: 'Verbal',
        timeOutLength: 135,
        questions: task14questions,
        questionCount: 30,
        practiceQuestions: [
            { id: 1400, question: `DEEP`, order: 0, choices: ['SHALLOW', 'UNDER', 'HIGH', 'NEW'], correctAnswer: 1 },
            { id: 1401, question: `FUNNY`, order: 0, choices: ['MONEY', 'AWKWARD', 'AMUSING', 'LOYAL'], correctAnswer: 3 },
        ],
        complete: false
    }
];

