import { Injectable, signal } from '@angular/core';
import { EvalueeService } from './evaluee.service';
import { AptitudeTask } from '@career-scope/models';
import { BaseAptitudeService } from './base-aptitude.service';


@Injectable()
export class ImagesService {

  images: string[] = [];
  loadedNum = 0;
  loaded = signal(false);

  constructor(
    private es: EvalueeService,
    private as: BaseAptitudeService
  ) {
    const evalueeTasks = this.as.getTasks()();

    if (evalueeTasks.length !== 0 && !this.loaded()) {
      this.populateImages(evalueeTasks.filter(t => !t.complete));
    }
  }

  populateImages(tasks: AptitudeTask[]) {

    tasks.forEach(task => {
      if (!task.complete) {
        task.practiceQuestions.forEach(pq => {
          if (task.questionType === 'Graphic') {
            this.images.push('assets/images/' + task.legacyCode + '/' + task.legacyCode + '_' + pq.question);
          }
          pq.choices.forEach(c => {
            if (task.choiceType === 'Graphic') {
              this.images.push('assets/images/' + task.legacyCode + '/' + task.legacyCode + '_' + c);
            }
          });
        });
        task.questions.forEach(q => {
          if (task.questionType === 'Graphic') {
            this.images.push('assets/images/' + task.legacyCode + '/' + task.legacyCode + '_' + q.question);
          }
          q.choices.forEach(c => {
            if (task.choiceType === 'Graphic') {
              this.images.push('assets/images/' + task.legacyCode + '/' + task.legacyCode + '_' + c);
            }
          });
        });
      }
    });

    if (this.images.length) {
      this.loadImages();
    }

  }

  loadImages() {
    console.log('Pre-loading', this.images.length, 'images');
    // eslint-disable-next-line no-restricted-syntax
    console.time('Loading');

    this.images.forEach(imgSource => {
      const img = new Image();
      img.src = imgSource;
      img.onload = () => {
        this.imgLoaded();
      };
    });
  }

  imgLoaded() {
    this.loadedNum++;

    if (this.loadedNum === this.images.length) {
      this.loaded.set(true);
      // eslint-disable-next-line no-restricted-syntax
      console.timeEnd('Loading');
    }
  }
}
