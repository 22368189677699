import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, from, Observable, of, Subscription } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { EvalueeService } from './evaluee.service';
import { Evaluee, Register, User } from '@career-scope/models';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { MatDialog } from '@angular/material/dialog';
import { IdleDialogComponent } from '../user/idle-dialog/idle-dialog.component';
import { FeaturesSecurityService } from './features-security.service';
import { captureException, setUser } from '@sentry/angular';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { collection, collectionData, CollectionReference, deleteDoc, doc, docData, DocumentReference, Firestore, getDoc, query, setDoc, updateDoc, where } from '@angular/fire/firestore';
import { Auth, authState, createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithCustomToken, signInWithEmailAndPassword, signOut, UserCredential } from '@angular/fire/auth';
import { browserSessionPersistence, setPersistence } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<User | null | undefined>;
  type: 'login' | 'reset' | 'support' = 'login';
  loading = false;
  serverMessage: BehaviorSubject<string> = new BehaviorSubject<string>('');
  cscopeUserId: string | null = null;

  idleStartSubscription: Subscription | null = null;
  idleTimeoutSubscription: Subscription | null = null;
  idleOpen = false;


  constructor(
    private auth: Auth,
    private firestore: Firestore,
    private functions: Functions,
    private es: EvalueeService,
    private fss: FeaturesSecurityService,
    private router: Router,
    private idle: Idle,
    private dialog: MatDialog
  ) {
    // Idle check can be set up once, it doesn't get triggerred until the autologout property is set
    this.setUpIdleCheck();

    // User moves through firebase auth -> Users Collection -> then returnes Evaluee from Portal Collection
    this.user$ = authState(this.auth).pipe(
      switchMap(firebaseUser => {
        if (firebaseUser) {
          const userDocRef = doc(this.firestore, `users/${firebaseUser.uid}`);
          return docData(userDocRef).pipe(
            take(1), // The user record won't change
            map(user => user as User),
            tap(cscopeUser => {
              if (!cscopeUser) {
                this.serverMessage.next('This email is authenticated, but no user record is found');
                return;
              }
              // If there is an evaluee but auth does not match then sign out logic
              if (this.es.evaluee?.value && this.es.evaluee.value.uid !== cscopeUser.evalueeId && this.es.evaluee.value.uid !== cscopeUser.uid) {
                this.signOut();
                return;
              }
              this.cscopeUserId = cscopeUser.uid;
              setUser({ id: cscopeUser.uid });
            })
          );
        } else {
          // If there is an evaluee but auth is signed out then sign out logic
          if (this.es.evaluee?.value?.uid) {
            this.signOut();
          }
          return of(null);
        }
      })
    );
  }

  get isLogin() {
    return this.type === 'login';
  }


  get isPasswordReset() {
    return this.type === 'reset';
  }

  async signIn(email: string, password: string): Promise<string | null> {
    this.loading = true;
    let credential: UserCredential | null = null;
    this.serverMessage.next('');

    try {
      if (this.isLogin) {
        credential = await signInWithEmailAndPassword(this.auth, email, password);
        const userDocRef = doc(this.firestore, 'users', credential.user.uid);
        const userDoc = await getDoc(userDocRef);
        
        if (userDoc.exists()) {
          this.updateUserLastLoggedInDate(credential.user.uid);
        } else {
          console.error('User document does not exist for UID:', credential.user.uid);
          captureException(new Error(`User document does not exist for logged in user UID: ${credential.user.uid}`), {
            extra: {
              uid: credential.user.uid,
              email: email
            }
          });
        }
        
        this.loading = false;
        this.router.navigate(['/']);
      }

      return credential?.user?.uid || null;

    } catch (err: unknown) {
      this.loading = false;
      this.serverMessage.next(err as string);
      return null;
    }
  }

  async passwordReset(email: string): Promise<boolean> {
    this.loading = true;
    this.serverMessage.next('');

    try {
      await sendPasswordResetEmail(this.auth, email);
      this.serverMessage.next('Password Reset email requested.');
      this.loading = false;
      return true;
    } catch (err: any) {
      this.loading = false;
      this.serverMessage.next(this.translateFirebaseErrorMessage(err.code, err.message));
      return false;
    }
  }

  translateFirebaseErrorMessage(code: string, message: string): string {
    if (code === 'auth/missing-email') {
      return 'No e-mail was specified';
    }
    if (code === 'auth/invalid-email') {
      return 'The email address is formatted incorrectly';
    }
    if (code === 'auth/user-not-found') {
      return 'There is no user in the CareerScope system with this email address';
    }
    console.log(code, message);
    return message;
  }

  async newRegisterCreateAccount(email: string, password: string, portalId: string, selfRegistration: boolean, evalueeId: string | null): Promise<string | null> {
    this.loading = true;
    this.serverMessage.next('');

    try {
      const credential = await createUserWithEmailAndPassword(this.auth, email, password);
      // If self registration wait for user data to be updated for selfRegistration function
      if (selfRegistration) { // EvalueeId on user doc gets updated in portalSelfRegistration cloud function
        await this.createUserData(credential.user?.uid, credential.user?.email, portalId, null);
        this.loading = false;
        return credential?.user?.uid || null;
      }
      await this.createUserData(credential.user?.uid, credential.user?.email, portalId, evalueeId);
      this.loading = false;
      return credential?.user?.uid || null;
    } catch (err) {
      const error = String(err);

      if (!error.includes('email-already-in-use')) {
        this.serverMessage.next(error);
      }

      if (error.includes('email-already-in-use')) {
        return await this.registerUserPreviouslyAuthed(email, password, portalId, evalueeId);
      }

      this.loading = false;
      return null;
    }
  }

  signInUsernamePasswordAccount(username: string, secret: string, portalId: string | null): Observable<{ token: string; }> {
    this.serverMessage.next('');
    const callable = httpsCallable<{ username: string; secret: string; portalId: string | null; }, { token: string }>(this.functions, 'createLogin');
    return from(callable({ username, secret, portalId })).pipe(
      map(res => res.data as { token: string }),
      catchError(err => {
        this.serverMessage.next(err.message);
        throw err;
      })
    );
  }

  private async registerUserPreviouslyAuthed(email: string, password: string, portalId: string, evalueeId: string | null): Promise<string | null> {
    try {
      const credential = await signInWithEmailAndPassword(this.auth, email, password);

      if (credential?.user?.uid) {
        await this.createUserData(credential.user?.uid, credential.user?.email, portalId, evalueeId);
      }
      return credential?.user?.uid || null;
    } catch (err) {
      const error = String(err);
      this.serverMessage.next('This email is already in use - attempted to sign in and received error: ' + error);
      this.loading = false;
      return null;
    }
  }

  async signInWithToken(token: string) {
    try {
      console.log('signing in with token');
      const auth = await signInWithCustomToken(this.auth, token);
      const user = auth.user;

      await this.updateUserLastLoggedInDate(user?.uid || '');
      this.router.navigate(['/']);
    } catch {
      this.serverMessage.next('Unable to sign in. Please contact your counselor');
    }
  }

  async signInWithExternalEvaluee(jwt: string | null) {
    this.loading = true;
    if (!jwt) {
      console.error('No JWT provided for external evaluee login');
      this.serverMessage.next('Unable to sign in. Please contact your counselor');
      this.loading = false;
      return;
    }

    try {
      const createSSOToken = httpsCallable(this.functions, 'createExternalAssessmentAppLoginToken');
      const result = await createSSOToken({ jwt });
      const { token } = result.data as { token: string };

      if (!token) {
        console.error('No token returned for external evaluee login');
        this.serverMessage.next('Unable to sign in. Please contact your counselor');
        this.loading = false;
        return;
      }

      await this.signInWithToken(token);
    } catch (error) {
      console.error('Error creating external evaluee login token:', error);
      this.serverMessage.next('Unable to sign in. Please contact your counselor');
      this.loading = false;
    }
  }

  signOut(selfRegistrationLink?: string) {
    this.cscopeUserId = null;
    this.es.signOut();
    this.idle.stop();

    signOut(this.auth).then(() => {
      console.log('user is logged out');

      if (selfRegistrationLink) {
        this.router.navigateByUrl('/portal/' + selfRegistrationLink);
        return;
      }
      this.router.navigateByUrl('/login');
      location.reload();
    });
  }

  async signOutNoRedirect() {
    this.cscopeUserId = null;
    this.es.signOut();
    await signOut(this.auth);
  }

  private async createUserData(uid: string | undefined, email: string | undefined | null, portalId: string, evalueeId: string | null) {
    if (uid && email) {
      const userRef = doc(this.firestore, 'users', uid) as DocumentReference<User>;

      const data = {
        uid: uid,
        portalId,
        email: email,
        created: new Date(),
        lastLoggedIn: new Date(),
        anonymous: false,
        evalueeId
      };

      await setDoc(userRef, data, { merge: true });

      if (evalueeId) {
        const evalueeRef = doc(this.firestore, 'portals', portalId, 'evaluees', evalueeId) as DocumentReference<Evaluee>;
        await updateDoc(evalueeRef, { status: 'in progress', userId: uid });
      }
    }
    return null;
  }

  private updateUserLastLoggedInDate(uid: string) {
    if (!uid) {
      return;
    }
    
    const userRef = doc(this.firestore, 'users', uid) as DocumentReference<User>;

    const data = {
      lastLoggedIn: new Date(),
    };

    updateDoc(userRef, data);
  }

  // Logic for this is also in apps/career-scope-admin evaluee.service
  // TODO: Move to a firebase function as seeker won't have access to user collection
  checkForExistingEmail(email: string): Observable<boolean> {
    const registrationsCollectionRef = collection(this.firestore, 'registrations') as CollectionReference<Register>;
    const usersCollectionRef = collection(this.firestore, 'users') as CollectionReference<User>;

    const registrationsQuery = query(registrationsCollectionRef, where('email', '==', email));
    const usersQuery = query(usersCollectionRef, where('email', '==', email));

    const registrations$ = collectionData<Register>(registrationsQuery);
    const users$ = collectionData<User>(usersQuery);

    return combineLatest([registrations$, users$]).pipe(
      map(([registrations, users]) => {
        return !!registrations.length || !!users.length;
      })
    );
  }

  // TODO: Look at moving a firebase function
  deleteUser(uid: string) {
    const userRef = doc(this.firestore, 'users', uid) as DocumentReference<User>;
    deleteDoc(userRef);
  }

  setUpIdleCheck() {
    this.fss.getAutoLogout().subscribe(autoLogout => {
      if (!autoLogout) {
        return;
      }

      setPersistence(this.auth, browserSessionPersistence);

      if (!this.idleStartSubscription) {
        this.idle.setIdle(10 * 60);
        this.idle.setTimeout(2 * 60);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idleStartSubscription = this.idle.onIdleStart.subscribe(() => {
          // Only open new idle modal if there currently is not one open
          if (!this.idleOpen) {
            const idleDialog = this.dialog.open(IdleDialogComponent, { minHeight: '10rem', height: 'auto' });
            idleDialog.afterOpened().subscribe(() => this.idleOpen = true);
            idleDialog.afterClosed().subscribe(res => {
              if (res) {
                this.signOut();
                return;
              }
              this.idleOpen = false;
            });
          }
        });
      }

      if (!this.idleTimeoutSubscription) {
        this.idleTimeoutSubscription = this.idle.onTimeout.subscribe(() => this.signOut());
      }

      this.idle.watch();
    });
  }
}
