import { inject, Injectable, LOCALE_ID, signal } from '@angular/core';
import { Interest, InterestQuestion, InterestScore } from '@career-scope/models';
import { testerlyInterests, testerlyInterestQuestions, spanishTesterlyInterests, spanishTesterlyInterestQuestions } from '@career-scope/libs/data';
import { BaseInterestService } from './base-interest.service';
import { RatingOption } from '../shared/components/rating-options/rating-options.component';

@Injectable()
export class TesterlyInterestService extends BaseInterestService {
  locale = inject(LOCALE_ID);
  interests = signal<Interest[]>(this.locale === 'en' ? testerlyInterests : spanishTesterlyInterests);
  interestQs = signal<InterestQuestion[]>(this.locale === 'en' ? testerlyInterestQuestions : spanishTesterlyInterestQuestions);
  topInterests = signal<(Interest & InterestScore)[]>([]);
  ratingConfigOptions = signal<RatingOption[]>([
    { value: 1, answerValue: 1, label: this.locale === 'en' ? 'Strongly Dislike' : 'Muy poco interesante', emoji: '😫', color: '#ff4444' },
    { value: 2, answerValue: 2, label: this.locale === 'en' ? 'Dislike' : 'Poco interesante', emoji: '☹️', color: '#ff8c44' },
    { value: 3, answerValue: 3, label: this.locale === 'en' ? 'Neutral' : 'Neutro', emoji: '😐', color: '#ffcd44' },
    { value: 4, answerValue: 4, label: this.locale === 'en' ? 'Like' : 'Interesante', emoji: '🙂', color: '#88cc44' },
    { value: 5, answerValue: 5, label: this.locale === 'en' ? 'Strongly Like' : 'Muy interesante', emoji: '😊', color: '#44bb44' }
  ],);
  INTEREST_INVENTORY_TASK_ID = 26;

  constructor() {
    super();
  }
} 