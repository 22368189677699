import { Injectable, signal } from '@angular/core';
import { BaseAptitudeService } from './base-aptitude.service';
import { CareerScopeAptitudeService } from './career-scope-aptitude.service';
import { combineLatest, map, Subscription } from 'rxjs';

@Injectable()
export class AptitudeServiceProvider extends BaseAptitudeService {
  private currentService?: BaseAptitudeService;
  private loadSubscription?: Subscription;  

  constructor() {
    super();
    this.initializeService();
  }

  private initializeService() {
    this.loadSubscription?.unsubscribe();

    this.loadSubscription = combineLatest([
      this.evalueeService.evaluee.pipe(),
      this.assessmentService.assessment.pipe(),
    ]).pipe(
      map(([evaluee, assessment]) => {

        if (evaluee === undefined || assessment === undefined) {
          this.currentService = undefined;  
          throw new Error(`${!evaluee ? 'Evaluee' : 'Assessment'} not found`);
        }

        if (evaluee === null || assessment === null) {
          this.currentService = undefined;
          return;
        }

        if (this.currentService) {
          return;
        }

        this.currentService = new CareerScopeAptitudeService();
        this.currentService.loadExerciseComplete(assessment.taskResults);
        this.currentService.updateExerciseTimeModifier();
      })
    ).subscribe();
  }

  get aptitudes() { return this.currentService?.aptitudes || signal([]); }
  get tasks() { return this.currentService?.tasks || signal([]); }


} 