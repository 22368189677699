import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AssessmentService } from '../../_services/assessment.service';
import { filter, map, take } from 'rxjs';

export const interestTypeGuard: CanActivateFn = (route) => {
  const router = inject(Router);
  const assessmentService = inject(AssessmentService);
  const requestedPath = route.routeConfig?.path;

  return assessmentService.assessment.pipe(
    filter(assessment => assessment !== null),
    take(1),
    map(assessment => {


      if (!assessment) {
        return router.parseUrl('/');
      }

      // const isTesterly = assessment.interestInventoryType === 'testerly';
      // const isCareerScope = assessment.interestInventoryType === 'career-scope' || !isTesterly;



      // if (isTesterly && requestedPath !== 'inttest') {
      //   return router.parseUrl('/inttest');
      // }
      
      // if (isCareerScope && requestedPath !== 'inttest3') {
      //   return router.parseUrl('/inttest3');
      // }

      return true;
    })
  );
}; 